<template>
<div class="ml-4">
    
    <InlineNotification class="bg_singer" Heading="Pending gigs">
        <template slot="body">
          <div class="grid grid-flow-row gap-4">
          <p>Below is the list of gigs that you have offered to artists but have not confirmed yet, and upcoming booked gigs.</p>
          <p>Click “view offers” to see who is available, and confirm the artist for that gig.</p>
          <p>If there are no available artists, let GigPig find available artists in your area.
          </p></div>
        </template>
    </InlineNotification>


    <div>
      <div class="mt-12 flex-row lg:flex lg:space-x-24 space-y-6 lg:space-y-0">
        <div>
          <div class="flex space-x-2 items-center">
            <OfficeBuildingIcon class="text-gp_pink-default-500"/>
            <span class="font-black">Venues</span>
          </div>
          <div class="w-80 mt-4">
            <MultiselectFilter
            :options="compVenues"
            :selectedOptionProps="selectedVenues"
            :searchable="true"
            :multiple="true"
            :taggable="false"
            :close-on-select="false"
            :show-labels="false"
            :displayText="venueDisplayText"
            @optionSelected="venueSelected"
            @optionDeselected="venueDeselected"
            :placeHolder="'Filter by venue'"
            :track-by="'id'"
            ></MultiselectFilter>
          </div>
        </div>
      </div>


      <div class="mt-12 flex-row lg:flex lg:space-x-24 space-y-6 lg:space-y-0">
        <div>
            <div class="flex space-x-2 items-center">
              <ClipboardListIcon class="text-gp_pink-default-500"/>
              <span class="font-black">Gig Status</span>
            </div>
            <div class="w-80 mt-4">
              <MultiselectFilter
                :options="statuses"
                :selectedOptionProps="selectedStatuses"
                :searchable="false"
                :multiple="true"
                :taggable="false"
                :close-on-select="false"
                :show-labels="false"
                :displayText="statusDisplayText"
                @optionSelected="statusSelected"
                @optionDeselected="statusDeselected"
                :placeHolder="'Filter by status'"
                :track-by="'status'"
                ></MultiselectFilter>
            </div>
        </div>
        <div v-show="isGenre">
            <div class="flex space-x-2 items-center">
              <TagIcon class="text-gp_pink-default-500"/>
              <span class="font-black">Gig Tags</span>
            </div>
            <div class="w-80 mt-4">
              <TRichSelect 
                multiple
                v-model="setTagFilters"
                ref="iconselect"  
                class="col-span-12 lg:col-span-5 cursor-pointer"
                :close-on-select="false" valueAttribute="id"  textAttribute="name"
                :options="venue_custom_tags"
                placeholder="Filter by custom tag" 
              >  
                <template slot="option" slot-scope="{ index, isHighlighted, isSelected, className, option }">                                 
                  <div class="flex gap-4 py-2">
                    <component :is="icons[option.raw.icon]" class="ml-3"/>  {{ option.raw.name }} 
                  </div>
                </template>
              </TRichSelect> 
            </div>
        </div>
      </div>

      <div class="mt-12">
        <div class="flex space-x-2 items-center">
          <CalendarIcon class="text-gp_pink-default-500"/>
          <span class="font-black">Filter by date</span>
        </div>
        <div class="flex-row lg:flex lg:space-x-9 space-y-3 lg:space-y-0 mt-4">
          <div>
            <div class="col-span-3 lg:col-span-1">
              <input :min="dateMinDate" ref="start" placeholder="Start date" type="date" v-model="dateFilterStart" class="datepick cursor-pointer h-full w-80 rounded-lg border-gray-300"/>
            </div>
            <p class="text-xs font-thin ml-1 mt-2">The start date of the gigs to display</p>
          </div>
          <div class="hidden lg:block">
            <ArrowCircleRightIcon class="text-gp_pink-default-400 mt-4" />
          </div>
          <div>
            <div class="col-span-3 lg:col-span-1">
              <input ref="end" type="date" v-model="dateFilterEnd" class="datepick h-full w-80 cursor-pointer rounded-lg border-gray-300"/>
            </div>
            <p class="text-xs font-thin ml-1 mt-2">The end date of the gigs to display</p>
          </div>
        </div>
      </div>
    </div>

    <!-- NEW BUILD UI REVAMP -->
    <div class="mt-16 py-2 bg-[#0D1C43] rounded-lg max-w-5xl flex space-x-44 uppercase text-gray-200 text-sm font-medium">
        <div class="ml-4"/>
        <div class="w-52 flex space-x-3 invisible xl:visible">
          <div class="bg-gp_pink-default-400 w-[2px] h-5 rounded-lg"/>
          <span>Gig Details</span>
        </div>
        <div class="hidden xl:flex space-x-3 pl-16">
          <div class="bg-gp_pink-default-400 w-[2px] h-5 rounded-lg"/>        
          <span>Gig Status</span>
        </div>

    </div>

    <div class="flex flex-col lg:flex-row py-8 max-w-5xl border my-3 rounded-xl shadow-lg bg-white" v-for="(gig, index) in rows" :key="'chaserow' + index">

      <div class="flex">
        <div class="flex flex-col justify-center items-center px-10 w-48">
          <img class="rounded-full h-24 w-24 object-cover border-2 border-gp_pink-default-400" :src="imageCheck(gig.venue.icon)" />
        </div>

        <div class="w-96 flex space-x-5">
          <div class="bg-gp_pink-default-400 w-[2px] h-full rounded-lg"/>   
            <div>
              <GigTagByStatus
                class="lg:hidden"
                :status="gig.status"
              />
              <p class="font-bold mt-2 lg:mt-0">{{gig.venue.name}}</p>
              <p class="mt-2">{{gig.name}}</p>
              <span class="block">{{fullDate(gig.start)}}</span>

              <div class="mt-2 mb-4 space-x-3 flex items-center">
								<div v-if="gig.custom_tags.length > 0" class="flex items-center justify-center px-3 py-1 rounded-3xl space-x-2 bg-black ">
									<component :is="icons[firstCustomTagIconName(gig.custom_tags[0])]" class="w-4 h-4" :style="{ color: firstCustomTagIconColour(gig.custom_tags[0]) }"/>
									<span class="text-white text-xs">{{ firstCustomTagName(gig.custom_tags[0]) }}</span>
								</div>
								<div>
									<span class="relative inline-flex flex-col items-center group" v-if="gig.custom_tags.length > 0">
										<span v-if="gig.custom_tags.length > 1"
										class="text-sm font-medium underline underline-offset-2 cursor-pointer"
										>+{{ gig.custom_tags.length - 1 }} more</span>
										<div class="absolute z-10 bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
										<span class="p-4 bg-black rounded-sm shadow-lg w-48">
											<ul class="list-none">
											<li v-for="tag in gig.custom_tags" :key="tag.id" class="text-white flex space-x-4 items-center space-y-1">
												<component :is="icons[tag.icon]" class="w-5 h-5" :style="{ color: tag.icon_colour }"/>
												<span>{{ tag.name }}</span>
											</li>
											</ul>
										</span>
										<div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
										</div>
									</span>
								</div>
							</div>

              <div class="flex items-center space-x-2">
                <router-link :to="getGigRoute(gig)"> 
                  <button class="rounded-full py-1.5 px-4 text-xs font-semibold cursor-pointer tracking-wider text-white  bg-gradient-to-r from-pink-400 via-gp_pink-default-500 to-gp_pink-default-500 hover:bg-gp_pink-default-500 hover:text-white transition ease-out duration-700">{{ getGigRouteLabel(gig) }}</button>
                </router-link>

                <button v-show="hasTagAccess && isGenre" @click="openUpdateTagsModal(index, gig)" class="rounded-full py-1 px-4 text-xs font-semibold cursor-pointer tracking-wider text-gp_pink-default-600 border-gp_pink-default-500 border-2 hover:bg-pink-400 hover:text-white transition ease-out duration-700">Manage Tags</button>
              </div>
            </div>
        </div>
      </div>


      <div class="w-96 pl-16 hidden lg:block">
        <GigTagByStatus
          :status="gig.status"
        />

        <div class="">
          <div class="uppercase text-xs mt-3" v-if="gig.cancelled"> 
            <span>This gig has been cancelled.</span>
          </div>
          <div class="uppercase text-xs mt-3" v-else-if="gig.status === 'UNPUBLISHED'"> 
            <span class="">Start choosing artists.</span>
          </div>
          <div class="uppercase text-xs mt-3 space-y-1" v-else-if="gig.bookings.length == 0"> 
            <span>{{gig.bids_count}} offers found</span>
            <span class="block">{{timeUntilGig(gig.start)}}</span>
          </div>
          <div class="" v-else-if="gig.bookings && gig.bookings[0] && gig.bookings[0].artist"> 


              <div class="flex items-center space-x-3 mt-3">
                <ClockIcon class="w-4 h-4"/>
                <span class="block uppercase font-light text-xs">{{timeUntilGig(gig.start)}}</span>
              </div>


            <div class="flex space-x-4">
              <div class="flex flex-col justify-center items-center mt-6">
                <img class="rounded-full h-12 w-12 object-cover border-2 border-gp_pink-default-400" :src="artistImageCheck(gig.bookings[0].artist)" />
              </div>
              <div class="mt-6">
                <span class="text-gp_pink-default-500" v-if="gig.bookings && gig.bookings[0].artist">{{gig.bookings[0].artist.name}} booked</span>
                <!-- <span class="block text-indigo-500" v-if="gig.bookings[0].artist.telephone"><a :href="'tel:' + gig.bookings[0].artist.telephone">tel: {{gig.bookings[0].artist.telephone }}</a></span> -->
                
                <div class="flex items-center space-x-2 mt-1">
                  <PhoneIcon class="w-4 h-4"/>
                  <span class="text-sm">{{gig.bookings[0].artist.telephone }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="flex items-center justify-center w-44">
        <GigTagByStatus
          :status="gig.status"
        />
      </div> -->
    </div>

    <div class="max-w-5xl shadow overflow-hidden border-b">
      <Pagination
          @changePage="(n) => paginate_currPage = n"
          :paginate_from="paginate_from"
          :paginate_to="paginate_to"
          :paginate_total="paginate_total"
          :paginate_currPage="paginate_currPage"
          :paginate_lastPage="paginate_lastPage"
      ></Pagination>
    </div>

    <AmendCustomGigTag ref="updateGigTags" v-on:updatedTag="updatedTags($event)"/> 
</div>
</template>

<script type="text/javascript">


import { apiComputed, apiMethods } from '@/state/helpers.js'
import fullDate from  '@/utils/format-date-full.js'  
import InlineNotification from '../../components/notifications/inline.vue'; 
import NProgress from 'nprogress';
import Pagination from "@/components/elements/Pagination.vue";
import { DateTime } from 'luxon';
import { timeUntilGig } from '@/utils/time-until-gig.js';
import { OfficeBuildingIcon, ClipboardListIcon, CalendarIcon, ArrowCircleRightIcon, PhoneIcon, ClockIcon, TagIcon  } from "@vue-hero-icons/outline";
import GigTagByStatus from '../../components/iconsets/GigTagByStatus.vue'
import MultiselectFilter from '@/components/filters/MultiSelectFilter.vue'
import AmendCustomGigTag from '../../components/modal/gigs/AmendCustomTags.vue';
import  * as icons from "@vue-hero-icons/outline" 
import {mapState} from "vuex";
import client from '@/utils/client.js'
import { TRichSelect } from 'vue-tailwind/dist/components';

export default {
    name: "ChaseArtists",
  components:
  {
    Pagination,
    InlineNotification, 
    OfficeBuildingIcon, ClipboardListIcon, CalendarIcon, ArrowCircleRightIcon, PhoneIcon, ClockIcon, TagIcon,
    GigTagByStatus,
    MultiselectFilter,
    AmendCustomGigTag,
    TRichSelect
  },
  data() { 
      return {
        setTagFilters: [],
        venue_custom_tags: [],
        icons: icons,
        paginate_from: null,
        paginate_to: null,
        paginate_total: null,
        paginate_currPage: 1,
        paginate_lastPage: null,
        selectedVenues: [{id: 'all', name: 'All venues'}],
        dateMinDate: DateTime.now().toFormat('yyyy-MM-dd'),
        selectedStatuses: [{status: 'all', name: 'All'}],
        dateFilterStart: this.getCalendarStartDate(),
        dateFilterEnd: this.getCalendarEndDate(),
        rowsCopy: [],
        rows: [],
        compVenues: [],
        selectedIndex: null,
        statuses: [
          {
            name: 'All',
            status: 'all'
          },
          {
            name: 'Confirmed',
            status: 'CONFIRMED'
          },
          {
            name: 'Pending Response',
            status: 'NEEDS_RESPONSE'
          },
          {
            name: 'Searching for Artists',
            status: 'PENDING'
          },
          {
            name: 'Draft',
            status: 'UNPUBLISHED'
          },
          {
            name: 'Cancelled',
            status: 'CANCELLED'
          },
        ]
      }
  },
  watch:{
      setTagFilters(){
        this.loadData();
      },
      paginate_currPage() {
        this.loadData();
      },
      selectedVenues(){
        if (this.selectedVenues.length === 0) {
          this.selectedVenues = [{id: 'all', name: 'All'}];
        }
        this.paginate_currPage = 1;
        this.loadData()
			},
    dateFilterStart() {
      localStorage.setItem('pendingGigsStartDate', this.dateFilterStart)
      this.paginate_currPage = 1;
      this.loadData()
    },
    dateFilterEnd() {
      localStorage.setItem('pendingGigsEndDate', this.dateFilterEnd)
      this.paginate_currPage = 1;
      this.loadData()
    },
    selectedStatuses() {
      if (this.selectedStatuses.length === 0) {
        this.selectedStatuses = [{status: 'all', name: 'All'}];
      }
      this.paginate_currPage = 1;
      this.loadData()
    },
  },
  computed: {
    ...apiComputed,
    ...mapState({
			userRoles: (state) => state.user.rolesPermissionsSlugs,
		}),
    hasTagAccess()
    {
        if (this.userRoles && this.userRoles.length > 0) {
            return this.userRoles[0] == 'full-access';
        } else {
            return null; 
        }
    },
    userData(){
      return this.$store.getters['user/userData']
    },
    isGenre() {
      return this.userData && this.userData.email.includes('genremusic')
    },
		getGigRoute(){
			return (gig) => {
				if(!gig.published){
					return `gigs/${gig.id}/choose-artists`
				}else{
					if(gig.status === "PENDING" && gig.bids_count){
						return `gigs/${gig.id}/offers`
					}
					if(gig.status === "CONFIRMED" && gig.bookings.length){
						return `gigs/${gig.id}/bookings`
					}
					return `gigs/${gig.id}`
				}
				
			}
		},
		getGigRouteLabel(){
			return (gig) => {
				
				if(!gig.published){
					return 'Choose artists'
				}else{
					if(gig.status === "PENDING" && gig.bids_count){
						return 'View offers'
					}
					if(gig.status === "CONFIRMED" && gig.bookings.length){
						return 'View bookings'
					}
					return `View gig`
				}
				
			}
		},
    statusParams() {
      const stats = this.selectedStatuses[0].status === 'all' ? this.statuses.slice(1) : this.selectedStatuses;
      return stats.map((status) => status.status);
    },
    venueParams() {
      if (!this.compVenues.length || (this.compVenues[0].id === 'all' && this.compVenues.length === 1))
        return [];

      if (!this.selectedVenues.length || (this.selectedVenues[0].id === 'all' && this.selectedVenues.length === 1))
        return [];

      const venues = this.selectedVenues[0].id === 'all' ? this.compVenues.slice(1) : this.selectedVenues;
      return venues.map((venue) => venue.id);
    },
    statusDisplayText() {
      return this.selectedStatuses[this.selectedStatuses.length-1].name
    },
    venueDisplayText() {
      return this.selectedVenues[this.selectedVenues.length-1].name
    }
  },
  mounted(){
    client.get('custom-tags').then(response => (this.venue_custom_tags = response.data.data))
    this.loadVenues();
    this.loadData();
  },
  methods: {
    ...apiMethods,
    fullDate, 
    timeUntilGig,
    firstCustomTagName(val){
      return val.name
    },
    firstCustomTagIconName(val){
      return val.icon
    },
    firstCustomTagIconColour(val){
      return val.icon_colour
    },
    updatedTags(event) {
      this.rows[this.selectedIndex].custom_tags = event;
      this.rowsCopy[this.selectedIndex].custom_tags = event;
    },
    openUpdateTagsModal(index, gig) {
      this.selectedIndex = index;
      this.$refs.updateGigTags.updateGigTag(gig);
    },
    artistImageCheck(artist){
      return artist.image && artist.image.url ? artist.image.url : require('@/assets/images/bg_concert.png')
    },
    imageCheck(icon){
      return icon && icon.url ? icon.url : require('@/assets/images/bg_concert.png')
    },
    loadData() {

			NProgress.start();

      const params = {
        page: this.paginate_currPage,
        starts_between: `${this.dateFilterStart},${this.dateFilterEnd}`,
        include: ['venue', 'bookings', 'customTags'],
        statuses: this.statusParams,
        venue_ids: this.venueParams,
        custom_tags: this.setTagFilters,
      }

			this.getPendingGigs(params).then((response) => {
        this.rows = response.data;
        this.paginate_from = response.meta.from
        this.paginate_to = response.meta.to
        this.paginate_total = response.meta.total
        this.paginate_lastPage = response.meta.last_page
      }).finally(() => {
        this.rowsCopy = this.rows;
        NProgress.done();
      })
    },
    async loadVenues(){
      await Promise.all([
        this.$store.dispatch('venue/getAllVenues')
        .then((response) => {
          this.compVenues = [
            { name:"All venues", id:"all" },
            ...response
          ];
        }),
      ])
    },
    selectedStat(status) {
      return status === this.selectedStatuses[this.selectedStatuses.length - 1].status;
    },
    selectedVen(id) {
      return id === this.selectedVenues[this.selectedVenues.length - 1].id;
    },
    statusSelected(option) {
      if (option.status === 'all') {
        this.selectedStatuses = [{status: 'all', name: 'All'}];
      } else {
        const index = this.selectedStatuses.findIndex((element) => element.status === 'all');
        if (index !== -1) {
          this.selectedStatuses.splice(index, 1);
        }
        this.selectedStatuses.push(option);
      }
    },
    statusDeselected(option) {
      if (option.status !== 'all') {
        const index = this.selectedStatuses.findIndex((element) => element.status === option.status);
        if (index !== -1) {
          this.selectedStatuses.splice(index, 1);
        }
      }
    },
    venueSelected(option) {
      if (option.id === 'all') {
        this.selectedVenues = [{id: 'all', name: 'All venues'}];
      } else {
        const index = this.selectedVenues.findIndex((element) => element.id === 'all');
        if (index !== -1) {
          this.selectedVenues.splice(index, 1);
        }
        this.selectedVenues.push(option);
      }
    },
    venueDeselected(option) {
      if (option.id !== 'all') {
        const index = this.selectedVenues.findIndex((element) => element.id === option.id);
        if (index !== -1) {
          this.selectedVenues.splice(index, 1);
        }
      }
    },
    getCalendarStartDate() {
      const pendingGigsStartDate = localStorage.getItem('pendingGigsStartDate')
      if (pendingGigsStartDate) {
        return pendingGigsStartDate
      } else {
        return DateTime.now().toFormat('yyyy-MM-dd')
      }
    },
    getCalendarEndDate() {
      const pendingGigsEndDate = localStorage.getItem('pendingGigsEndDate')
      if (pendingGigsEndDate) {
        return pendingGigsEndDate
      } else {
        return DateTime.now().plus({years: 2}).toFormat('yyyy-MM-dd')
      }
    }
  }
}

</script>