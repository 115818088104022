<template>

    <div class="col-span-full">

        <FastBook
            ref="fastbook"
            v-on:fastBook="fastBooking($event)"
            v-on:closeModal="toggle = $event;"
            :currency-symbol="prefix"
        />

        <p class="col-span-full my-4">If you already know who you want for this gig, you can manually select them here.  To set up your favourites list, <router-link to="/artist-directory" class="text-gp_pink-default-500"> you can browse our artists here.</router-link></p>

        <div class="grid grid-flow-row lg:grid-flow-col lg:grid-cols-12 col-span-full gap-4 items-center">
            <TRichSelect 
							ref="faves" 
							class="col-span-12 lg:col-span-5"
                            :fetch-options="fetchFaveOptions"
							:close-on-select="true" valueAttribute="id" textAttribute="name" 
                            v-model="selectedFave"
                            :minimumInputLength="1"
							:placeholder="'Search for a favourite list to add to this gig'"                             
							@change="selectFave()" 
						>
                        <template
                            slot="option"
                            slot-scope="{ index, isHighlighted, isSelected, className, option }"
                            >
                            <div :class="className">
                                <div class="text-gray-800">
                                    {{ option.raw.name }} &nbsp;<span v-if="option.raw.is_shared"><strong>(shared)</strong></span>
                                </div>
                            </div>
                        </template>
                    </TRichSelect>
            <span class="col-span-12 lg:col-span-1 text-center">OR</span>
            <TRichSelect 
							ref="artistdirectoryselect"  
							class="col-span-12 lg:col-span-5"
							:close-on-select="true" valueAttribute="id"  textAttribute="name" v-model="selectedArtist"
							placeholder="Add a specific artist" 
                            :fetch-options="fetchOptions"
                            :minimum-input-length="2"
                            :delay="250"
                            noResultsText="Type to search for an artist"
							@change="selectArtist()"
                            @fastBook="fastBooking($event)" 
                            >
                        
                            <template slot="option" slot-scope="{ index, isHighlighted, isSelected, className, option }">
                               
                                <div class="flex p-1 items-center justify-center">

                                        <img loading="lazy" class="object-cover min-w-[50px] min-h-[50px] w-12 h-12 rounded-full" v-if="option.raw.image && option.raw.image.conversions.thumb" :src="option.raw.image.conversions.thumb" />
                                        <img loading="lazy" class="object-cover min-w-[50px] min-h-[50px] w-12 h-12 rounded-full" v-else-if="option.raw.image && option.raw.image.url" :src="option.raw.image.url" />
                                        <img loading="lazy" class="object-cover min-w-[50px] min-h-[50px] w-12 h-12 rounded-full" v-else :src="require('../../assets/images/icons/single_logo.png')" />
                                        <div class="flex p-4 w-full justify-between">
                                            <div class="flex flex-col">                                                
                                                <strong> {{ option.raw.name }}</strong> 
                                                <span class="text-xs">{{ option.raw.tier.name }}</span> 
                                            </div>
                                            
                                            <div class="flex flex-col  ml-4">
                                                <span class="text-xs">{{ option.raw.id }}</span>               
                                            </div>                                    
                                        </div>
                                    </div>
                            </template> 
                        
                        </TRichSelect> 

        </div>
        <!-- This example requires Tailwind CSS v2.0+ -->
        <ul role="list" class="grid grid-cols-1 mt-4 gap-2">
        <li class="grid grid-flow-row grid-cols-6 col-span-3 bg-white rounded-lg shadow divide-y divide-gray-200" v-for="(addedArtist, index) in addedArtists" v-bind:key="index">
            <div class="col-span-full lg:col-span-3 w-full flex items-center justify-between px-6 py-2 space-x-2">
            <div class="flex-1 truncate">
                <div class="flex items-center space-x-3">
                <h3 class="text-gray-900 text-sm font-medium truncate">{{addedArtist.artist.name}}</h3>
                <span class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                    {{addedArtist.artist.tier.name}}
                </span>
                </div> 
                     
            </div>
            </div> 
            <div class="-mt-px col-span-full lg:col-span-2 flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex-col flex p-2">  
                <InputText :name="'fee_' + addedArtist.id"  v-on:input="updateFee($event, addedArtist.artist);" v-bind:value="addedArtist.fee"  :label="'Fee ' + (addedArtist.artist.vat_number ? '+ VAT' : '')" :prefix="prefix" :placeholder="'Use ' + addedArtist.artist.tier.name + ' fee'">
                </InputText> 
                </div> 
            </div> 
            <div class="h-full col-span-full lg:col-span-1 divide-gray-200">
                <div class="flex flex-col p-2 text-center justify-center items-center h-full"> 
                <a v-if="allowRemoveArtist(addedArtist.id)" class="text-gp_pink-default-500 text-sm" @click="removeArtist(addedArtist.artist)">Remove Artist</a>
                <template v-if="fastBookBtnToggle && showFastBook(addedArtist.id)">           
                    <a class="text-blue-500 text-sm mt-3 cursor-pointer hover:text-gp_pink-default-500" @click="openModal(addedArtist.id)">Fast Book</a> 
                </template>  
                </div>  
            </div>
        </li>

        <!-- More people... -->
        </ul>
    </div>
</template>

<script>

import InputText from '../form/InputText.vue';

import { apiComputed, apiMethods } from '@/state/helpers.js';
import FastBook from '../../components/modal/FastBook.vue';
import client from "@/utils/client"
import NProgress from 'nprogress'

import {
  TRichSelect
} from 'vue-tailwind/dist/components'

export default {
    name: "SearchByFave",
    components: {
        InputText,
        TRichSelect,
        FastBook
    },
    
    mounted(){ 
 
    },
    props:{
        defaultFees:{
					type: Array,
					default: () => [],
				},        
        fastBookBtnToggle: Boolean,
        passed_tiers: Array,
        genres: Array,
        styles: Array,
        passed_artistdirectory: Array,
        passed_faveslists: Array, 
        is_fetching_directory: Boolean,
        is_fetching_faves: Boolean,
        selectMultipleArtist:{
            type:Boolean,
            required:false,
            default:true,
        },
        bids: {
            type: Array,
            required: false,
            default: () => [],},
        gigStatus: {
            type: String,
            default: '',
            required: false
        },
      prefix: null
    },
		data(){
        return {
            artistList: [],
            addedArtists: [],
            selectedArtist: null,
            selectedFave: null,
            toggle: false,
            tempFees: [],
            updateableDefaultFees: this.defaultFees,
            feeUpdated: null
        }
    },
		computed:{
            ...apiComputed,
			selectArtistDisabled(){
				return (!this.selectMultipleArtist && this.addedArtists.length > 0) || this.is_fetching_directory;
			},
		},
		watch:{
			defaultFees(updatedDefaultFees){
				this.updateableDefaultFees = updatedDefaultFees;
			}
		},
    methods:{
        ...apiMethods,
        fetchFaveOptions($searchQuery){

            NProgress.start();
            return client.get(`/favourites-lists-simple/?search=${$searchQuery}`).then((resp) =>{                
                return {results: resp.data.data};
            }).finally(() => 
            {
                NProgress.done();
            });
        },
        fetchOptions($searchQuery){
 
            let endpoint = `/artist-directory-super?filter[name]=${$searchQuery}`;

            NProgress.start();

            return client.get(endpoint).then((resp) => { 
                return {results: resp.data.data};
            }).finally(() => 
            {
                NProgress.done();
            });
 
        },
        setStaged($staged_artists){
            if ($staged_artists && $staged_artists.length > 0) {

                $staged_artists.forEach((added_artist) => {
                    this.updateFee(added_artist.supersede_fee.toString(), added_artist.artist)
                });

                this.$emit('updatedStaged', this.addedArtists);
            }
        },
        pushToArray ( arr, obj ) {

        var existingIds = arr.map((obj) => obj.id);

            if (! existingIds.includes(obj.id)) {
                arr.push(obj);
            } else {
                arr.forEach((element, index) => {
                    if (element.fee !== obj.fee) {
                        this.$emit('changesMade');
                    }
                    if (element.id === obj.id) {
                    arr[index] = obj;
                    }
                });
            }
        },
        updateFee($fee, $artist){  

            if ($artist)
            {
                this.pushToArray(this.addedArtists, {id: $artist.id, fee: parseFloat($fee), artist: $artist});
            }
        },
        setDefaultFees($fees){
            this.updateableDefaultFees = $fees;  
        },
        addArtistToTemplate($artist){  
 
            let fee = 0;
 
            if (this.updateableDefaultFees)
            {
                let $defaultFee = this.updateableDefaultFees.find((defaultFee) => 
                {
                    let tierID = defaultFee.tier_id;

                    if (!tierID){
                        tierID = defaultFee.tier.id; 
                    }

                    let artist_tier_id = $artist.tier_id;

                    if (!artist_tier_id)
                    {
                        artist_tier_id = $artist.tier.id;
                    }
 
                    return  tierID == artist_tier_id
                });

                if ($defaultFee)
                { 
                    fee = $defaultFee.requested_fee;
                }
            }

            this.updateFee(fee.toString(), $artist);     
        },
        selectArtist(){
            this.$emit('updatedStaged', this.addedArtists);
            this.addArtistToTemplate(this.$refs.artistdirectoryselect.selectedOption.raw);
        },
        removeArtist($artist){ 
            this.addedArtists.splice(this.addedArtists.findIndex(addedArtist => addedArtist.artist.id === $artist.id), 1);
            this.selectedArtist = null;
        },
        selectFave(){

            NProgress.start();
            client.get(`/favourites-lists/` + this.$refs.faves.selectedOption.raw.id).then((response) => {
				const artists = response.data.data.artists;
                artists.forEach((artist) => {
                    this.addArtistToTemplate(artist);
                });                
            }).finally(() => {
                NProgress.done();
                this.$emit('updatedStaged', this.addedArtists);
            });
                             
        },
        openModal($id){
            this.toggle = true;
            let $added = this.addedArtists[this.addedArtists.findIndex(addedArtist => addedArtist.artist.id === $id)];
            this.$refs.fastbook.show($added);
        },
        fastBooking(chosenArtist){
            this.$emit('fastBook', chosenArtist);
            this.toggle = false;
        },
        allowRemoveArtist(artistId) {

            if (this.bids.length === 0) {
                return true;
            }
            
            return this.bids.filter(bid => {
                return bid.artist.id === artistId && bid.status === 'CONFIRMED' && bid.gig.status === 'CONFIRMED';
            }).length === 0;
        },
        showFastBook(artistId) {
            return this.bids.filter(bid => {
                return bid.artist.id === artistId && bid.status === 'CONFIRMED' && bid.gig.status === 'CONFIRMED';
            }).length === 0;
        }
    }
}

</script>