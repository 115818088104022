<template>
  <div id="app" :class="(enable_branding ? 'whitelabel' : 'reskin') + ' ' + (is_dark ? 'dark_theme' : '') ">
    <notifications  position="top right"  :duration="1200">

    </notifications>
    

    <div class="impersonate-banner bg-red-500 text-white p-4" v-if="userData && is_impersonated">
      Impersonating {{ userData.email }} / {{ userData.name }}
    </div>
    <div class="home">
      <div class="min-h-screen flex bg-white-500"  v-if="!is_opus_handshake && loaded">
        <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
        <NavMobile  :override_logo="logo_url" />

        <!-- Static sidebar for desktop -->
        <NavDesktopSidebar :override_logo="logo_url" :is_dark_bg="is_dark" />

        <div class="flex flex-col w-0 flex-1 overflow-hidden app-content overflow-visible">
          <NavHeader />
          
          <router-link v-if="userData && userData.is_opus" :to="'/venues/' + userData.first_venue_id" class="refer_banner flex flex-col lg:flex-row gap-y-4 mb-6 text-sm bg-gp_pink-default-200 hover:bg-gp_pink-default-300 p-4 justify-between">

                  <div class="flex flex-col gap-2">
                      <div class="flex flex-col gap-2">
                        Filling in your venue profile and uploading photos of your venue will boost it's visibility across our venue directory.
                      </div>
                  </div> 
              <span class="text-right self-center">Complete venue profile <span aria-hidden="true">→</span></span>

          </router-link>
          
          <a v-if="!hide_referral_message"  href="https://docs.google.com/forms/d/10GfjJDVZ2xLuKy64TuFkHyvXJC_W6bqBHIP1AUwLfu8/edit?ts=6486d74d" target="_blank" class="refer_banner flex flex-col lg:flex-row gap-y-4 mb-6 text-sm bg-gp_pink-default-200 hover:bg-gp_pink-default-300 p-4 justify-between">
              <div class="flex  justify-center items-center  ">
          
              <HeartIcon class=" mr-4"/>
                  <div class="flex flex-col gap-2">
                      <div class="flex flex-col gap-2">
                        <p class="text-lg font-bold">Are you performing at a <strong>venue you love</strong> and they aren't booking you through GigPig?</p>
                         <p>Refer a venue today and you'll receive <strong>£50*</strong> Uber Eats or Trips credit as a thank you.</p>
                      </div>
                      <p class="text-xs">
                          * Credit will be supplied the following month after the venue's first booking.
                      </p>
                  </div>
              </div>
              <span class="text-right self-center">I'd like to refer a venue <span aria-hidden="true">→</span></span>
          </a>
          <main class="flex-1 relative  focus:outline-none mb-28">
            <div class="flex-row bg-[#fafafa]">
              <router-view class="3xl:min-w-75% sm:pr-4 app-route  " :key="$route.fullPath" /> 
            </div> 
          </main>
        </div>
      </div>
      <div v-else>
        <div class="min-h-screen bg-[#0f0533] flex justify-center items-center" 
        style="background-size:cover;background-image: url(https://gigpigcdn.ams3.cdn.digitaloceanspaces.com/branding/opus-gradient.jpeg);">
          <div class="flex flex-col gap-8 max-w-md h-20 text-white text-4xl text-center">
            <img src="https://gigpigcdn.ams3.cdn.digitaloceanspaces.com/branding/OpusApeiro_Logo_Landscape_Web_FullColourWhiteText.svg" class="h-full w-full" />
            <p class="text-2xl flex justify-center items-center gap-8"><Spinner v-if="!is_opus_complete" /> 
              <span v-html="opus_user_message"></span></p>
          </div>
        </div>
      </div>
    </div>
      <GlobalComponents/>
  </div>
</template>

<script>
import NavDesktopSidebar from "./components/nav/NavDesktopSidebar.vue";
import NavMobile from "./components/nav/NavMobile.vue";
import NavHeader from "./components/nav/NavHeader.vue";
import {layoutMethods} from '@/state/modules/layout.js'; 
import client from './utils/client';

import Spinner from './components/ui/spinner/Spinner.vue';

import {apiComputed, apiMethods} from '@/state/helpers.js';
import GlobalComponents from "@/components/GlobalComponents/GlobalComponents.vue";
import { HeartIcon } from "@vue-hero-icons/outline" 

export default {
  name: "App",
  methods: {
    ...layoutMethods,
    ...apiMethods,
    setOpus(){
      if (this.isOpus)
      { 
            this.hide_referral_message = true; 
            window.localStorage.setItem("initialRoute", 'venue');
   
      }
    },
    async loadBranding() {
      let $user = this.userData; 
      const domainBrand = this.domainBrand
      let brand = false
      let hasCustomDomain = domainBrand !== null && domainBrand !== undefined; 
   
      if (!hasCustomDomain && (!$user || $user.brand !== null) && window.location.href.indexOf("artist") > -1){
        this.hide_referral_message = false;
      } 

      if (($user && $user.is_opus) || this.isOpus){
        this.setOpus();
      }

      if ($user && $user.brand && (!$user.email || $user.email && !$user.email.includes('gigpig.uk'))) {
        brand = $user.brand
      } 
      else if (hasCustomDomain) {

          if (domainBrand.domain === window.location.host) {
            brand = domainBrand;
            document.title = domainBrand.name;
            document.querySelectorAll("[href*='favicon']").forEach(function($rel){
              $rel.setAttribute("href", "/img/icons/genre-favicon.png");
            });
          }
      }

      if (brand) {
        this.brand_image = brand.logo_url_internal;
        this.enable_branding = true;
        this.is_dark = brand.is_dark;
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = brand.css_file;
        document.head.appendChild(style);
 
      } 

    },
    setReferral(){
      let $user = this.$store.getters['user/userData'];
        
        if ($user)
        {
          const ref = window.localStorage.getItem('ref');

          if (ref){         
            client 
              .put('user/update-referral', { ref : ref }).finally(() => 
                window.localStorage.removeItem('ref')
              );
          }  
        }
    },
    loadIntercom(){
          
        let $user = this.$store.getters['user/userData'];
        
        if ($user && (window.location.hostname == "app.gigpig.uk" || window.location.hostname == "localhost") && !$user.is_opus)
        {

          client.get('/intercom/profiles')
            .then((resp) => { 

              let intercomObject = resp.data.data;
              intercomObject.api_base =  "https://api-iam.intercom.io";
              intercomObject.app_id = "r4g5pt4f";
              window.intercomSettings = intercomObject;
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/r4g5pt4f';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

            });

        } else {
            console.log("auth not initiated", $user);
        }

    },
    dispatchDictionary() { 
      this.$store.dispatch('fetchDictionary');
    },
  },
  data(){
    return { 
      loaded:false,
      is_artist_area: false,
      brand_CSS: '',
      brand_image: '',
      enable_branding: false,
      is_dark: false,
      is_impersonated: false,
      hide_referral_message: true,
      is_opus_handshake: false,
      is_opus_complete: false,
      opus_user_message: 'Please wait'
    }
  }, 
  props:
  {
    isOpus: Boolean
  },
  mounted(){  

    this.setOpus();

    this.loadBranding();
    this.dispatchDictionary();
    if (window.sessionStorage.getItem('imp')) {      
      this.is_impersonated = true;
    } 

    if (this.isOpus || window.sessionStorage.getItem('opus')){ 
      if (this.$router.history.pending && this.$router.history.pending.query.e_value){
      window.sessionStorage.setItem("opus", JSON.stringify({
        e_value: this.$router.history.pending.query.e_value,
        opus_bun:  this.$router.history.pending.query.opus_bun
      }));
    }

      this.is_opus_handshake = true; 
      setTimeout(() => {

        this.loginOpus(client).then((response) => {
          this.opus_user_message = response.message;
          this.is_opus_complete = true;
          this.is_opus_handshake = false;
                     
          //if(response.data.is_new == true){
            this.$router.push('/artist-directory');
          //} 
          //else{            
          // this.$router.push('/gigs/add');
         // }
        }).catch(() => {
 
          this.is_opus_handshake = true;
        });


      }, 6000) // fake a loading for splash screen
    }
  },
  created() {
    const unwatch = this.$watch(
      () => this.$route,
      (route) => {
        if (route.query && route.query.imp){
          window.sessionStorage.setItem('imp', route.query.imp);
        }
        unwatch();
      });
  },
  computed: {
    ...apiComputed,
    userData(){
        this.loadIntercom();
				return this.$store.getters['user/userData']
			},
    domainBrand(){
      return this.$store.getters['api/domainBrand']
    },
    logo_url(){
      return this.enable_branding ? this.brand_image : '';
    }
  },  
  watch: {
    '$route' () {      
        this.is_artist_area = this.$route.path.startsWith('/artist') && !this.$route.path.startsWith('/artist-directory');
        this.setToggleMobileMenu(false);
    },
    userData() {
        this.setReferral();
        this.loadBranding();
        this.loaded = true;
    },
    domainBrand() {
      this.loadBranding();
    }, 
  },
  metaInfo() {
    return { 
      title: this.app_title,
      titleTemplate: '%s',
      meta:[
        {
          name: 'viewport',
          content:  'width=device-width,initial-scale=1,user-scalable=no'
        }
      ],
      link:[
        { rel: 'icon', href: this.favicon_svg},
        { rel: 'alternate icon', href: this.favicon_ico}
      ]
    }
  },
  components: {
    NavMobile,
    NavDesktopSidebar,
    NavHeader,
    GlobalComponents,
    HeartIcon,
    Spinner
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.form .datepick div[tabindex^='-1'] {
     max-width:50px;
}
 
</style>
