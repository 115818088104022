<template>
	<div>
		
		<!-- -->

		<CalendarWrapper
			calendar-type="Gigs"
			:view-options="calendarViewOptions"
			:available-status-keys="calendarAvailableStatusKeys"
			:schedules="schedules"
			:selectedVenue="filteredVenues"
			@selected="scheduleSelected"
			@update="onCalendarUpdated"
			@refresh="onCalendarRefresh"
			@create="onCalendarCreate"
			id="calendarWrapper"
			ref="calendarwrapper"
			v-on:statusFiltered="filterByStatus($event)"
			v-on:tagFiltered="filterByTags($event)"
		>
			<template v-slot:filters>
				<!-- User Venue filter -->
				<TRichSelect 
					id="venue-selection" 
					ref="venuepicker" 
					name="venue-selection" 
					v-model="filteredVenues"
					placeholder="Filter by venue"                    
					:options="venues"
					value-attribute="id" 
					text-attribute="name" 
					class="w-full" @change="changeVenue()"
				/>
			</template>

			<template v-slot:additionalFilters>
				<div class="flex justify-end items-center col-span-full pt-1 pb-4">
					<span class="mr-4 text-sm md:text-md" v-if="toggleArtistsNames">Showing artist name</span>
					<span class="mr-4 text-sm md:text-md" v-else>Showing gig name</span>
					<SwitchToggle 
						:enabled="toggleArtistsNames"
						@updateEnable="onToggleArtistsNames"
					/>
				</div> 
			</template>

		</CalendarWrapper>
		<!-- Schedule modal -->
		<calendar-preview 
			ref="calendarPreview" 
			@refresh="parseGigs()"
			:view-type="'VENUE'"
			:bid-id="selectedBidId"
			:show-as-bid="false"
		/>

		<CalendarHover ref="calendarhover" id="tooltip"/>
	</div>
</template>

<script>
	import NProgress from 'nprogress'
	import { TRichSelect } from 'vue-tailwind/dist/components';
	import CalendarWrapper from '@/components/calendar/CalendarWrapper.vue';
	import CalendarPreview from '@/components/modal/CalendarPreview.vue';
	import CalendarHover from '@/components/modal/CalendarHover.vue';
	import SwitchToggle from '@/components/ui/form/SwitchToggle.vue'
	import { getStatusesFromTypes, getStatusFromType, hasGigBeenBooked  } from '@/state/models/gigStatus';
	import normalizeDate from '@/utils/normalizeDate';
  import {venueMethods} from "@/state/helpers";
	const { DateTime } = require("luxon");

	const VENUE_LOCK_KEY = "LOCK_VENUE_1";
	const ARTIST_TOGGLE_KEY = "SHOW_ARTIST_NAME";

export default {
	components:{
		TRichSelect,
		CalendarWrapper,
		CalendarPreview,
		CalendarHover,
		SwitchToggle
	},
	data(){
		return{
			mobile: false,
			gigs:null,
			venues:null,
			schedules:[],
			tooltip: false,
			filteredGigs:[],
			filteredVenues:null,
			isLoading:false,
			selectedBidId:null,
			calendarViewOptions:[
				{ value: 'month', text: 'Month view'}, 
				{ value: 'week', text: 'Week view'},
				{ value: 'day', text: 'Day view'},
			],
			calendarAvailableStatusKeys: getStatusesFromTypes([
				'UNPUBLISHED',
				'CANCELLED',
				'NEEDS_RESPONSE',
				'PENDING',
				'CONFIRMED',
				'NEEDS_SIGNOFF',
				'SIGNED_OFF',
			]),
			calendarParams:{
				currentDate: null,
				endDateRange: null,
				startDateRange: null,
				view: null,
			},
			toggleArtistsNames:false,
			loadingGigs:false,
			statusFilters: [
				'UNPUBLISHED',
				'CANCELLED',
				'NEEDS_RESPONSE',
				'PENDING',
				'CONFIRMED',
				'NEEDS_SIGNOFF',
				'SIGNED_OFF',
			],
			tagFilters: []
		}
	},
	computed:{
		bankHolidays() {
			return this.$store.getters['bankHolidays/bankHolidays']
		},
	},
	watch:{
		filteredVenues(){

			if (this.filteredVenues === 'all') {
				this.$refs.calendarwrapper.allVenueFilterSelected();
			} else {
				this.$refs.calendarwrapper.selectVenueFilterSelected();
			}
 
			localStorage.setItem(VENUE_LOCK_KEY, this.filteredVenues);
			//this.setCalendarItems();
		}
	},
	methods:{
    ...venueMethods,
		filterByStatus(event)
		{
			if (event.length) {
				this.statusFilters = event;
				this.parseGigs();
			} else {
				this.gigs = null;
				this.filteredGigs = [];
				this.setCalendarItems();
			}
		},
		filterByTags(event)
		{
			this.tagFilters = event;
			this.parseGigs();
		},
		changeVenue(){
			this.parseGigs();
		},
		parseGigToSchedule(gig){
			const status = getStatusFromType(gig.status)
			const time = DateTime.fromISO(gig.start).toUTC().toFormat('t').replaceAll(' ', '').toLowerCase(); 
			// Title
			const scheduleTitle = !this.toggleArtistsNames ? gig.name : hasGigBeenBooked(gig.status) ? gig.bookings.length && gig.bookings[0].artist !== null ? gig.bookings[0].artist.name : '-' : status.name;
			// End date/time
			let end = gig.end;
 
			let startHour = DateTime.fromISO(gig.start).toUTC().hour;
			let endHour = DateTime.fromISO(gig.end).toUTC().hour;

			if (endHour < startHour) {
				end = DateTime.fromISO(gig.start).toUTC().set({hours: 23, minutes: 59});
				// prevent calendar going across two days
			}

			return {
				id: gig.id,
				title: time + ' ' + scheduleTitle,
				category: 'time',
				start: normalizeDate(gig.start),
				end: normalizeDate(end),
				calendarId: 0,
				raw: gig,
				bgColor: status.bg,
				color: status.color,
				customStyle: 'background-color:' + status.bg
			}
		},
		parseGigs(){

			this.gigs = null;
			this.filteredGigs = [];

			let range = {
				'from' : DateTime.fromJSDate(this.calendarParams.startDateRange).toFormat('yyyy-MM-dd'), 
				'to': DateTime.fromJSDate(this.calendarParams.endDateRange).plus({days: 1}).toFormat('yyyy-MM-dd'),	
			}

			if(this.filteredVenues != "all"){
				range.venue_id = this.filteredVenues;
			} 

			range.statuses = this.statusFilters;
			range.tags = this.tagFilters;
	
			NProgress.start();

			this.$store.dispatch('gig/getGigsCalendarByRange', range)
			.then((response) => {
				this.gigs = JSON.parse(JSON.stringify(response));
				this.filteredGigs = this.gigs
					.filter((g) => {
						return g.status && this.calendarAvailableStatusKeys.filter(i => i.type === g.status).length > 0
					})
					.map((fg) => this.parseGigToSchedule(fg))
					this.setCalendarItems();

			})
			.finally(() => {
				this.setCalendarItems();
				NProgress.done();
				this.loadingGigs = false;
			})
			.catch((err) => {
				console.log(err)
				NProgress.done();
				this.loadingGigs = false;
			})

		},

		setCalendarItems(){
 
			this.schedules = [];
			let schedules = [ ...this.filteredGigs ]
			// check for filter
			if(this.filteredVenues && this.filteredVenues !== "all"){
				schedules = schedules.filter(s => String(s.raw.venue.id) === String(this.filteredVenues));
			}

			const bankHolidays = [...this.bankHolidays]
					.map((bankHoliday) => {
							return {
									title: bankHoliday.region === 'England and Wales' ? bankHoliday.title : `${bankHoliday.title} (${bankHoliday.region})`,
									category: 'time',
									start: bankHoliday.date,
									calendarId: 0,
									bgColor: '#E0BBE4',
									color: '#E0BBE4',
									customStyle: 'background-color:' + '#E0BBE4'
							}
					})

			this.schedules = [...schedules, ...bankHolidays];
 

		},

		scheduleSelected(schedule){
			if(!this.$store.getters['user/hasManageGigsPermission']){
				return null;
			}
			let preview = this.$refs.calendarPreview;
			preview.item = schedule.raw;
			this.$refs.calendarhover.tooltip = false;
			preview.viewModal = true;
		}, 
		onCalendarUpdated($event){
			this.calendarParams = $event
			this.parseGigs();
		},
		onCalendarRefresh(){
		
		},
		onCalendarCreate(event){
			if(this.$store.getters['user/hasManageGigsPermission']){
				this.$router.push({
					path: "/gigs/add", 
					query: {
						start: event.start._date.toISOString(),
						end: event.end._date.toISOString() 
					}
				});
			}
		},
		onToggleArtistsNames(){
			this.toggleArtistsNames = !this.toggleArtistsNames
			this.parseGigs();
			localStorage.setItem(ARTIST_TOGGLE_KEY, this.toggleArtistsNames);
		},
		setKeysFromLocalStorage(){
			let venueKeyValue = localStorage.getItem(VENUE_LOCK_KEY);
			if (localStorage.getItem(VENUE_LOCK_KEY)){ 
				this.filteredVenues = Number(venueKeyValue)
			}

			let artistKey = localStorage.getItem(ARTIST_TOGGLE_KEY);
			if (artistKey){
				this.toggleArtistsNames = Boolean(artistKey);
			}
		},
		validateLocalStorageFilterValues() {
			let storedVenueFilter = localStorage.getItem(VENUE_LOCK_KEY);

			if (storedVenueFilter !== null && storedVenueFilter !== 'all') {
					if (this.venues.map(item => {
						return item.id
					}).indexOf(parseInt(storedVenueFilter)) === -1) {
						localStorage.removeItem(VENUE_LOCK_KEY)
					}
			}
		}
	},
	mounted(){
		this.isLoading = true;
		NProgress.start();
    this.getVenuesCalendarList()
		.then((response) => {
			this.venues = [
				{ name:"All venues", id:"all" },
				...response
			];

			this.filteredVenues = this.venues[1].id;


			this.validateLocalStorageFilterValues()

			this.setKeysFromLocalStorage();

			this.isLoading = false;
			if(!this.loadingGigs){
				NProgress.done();
			}
		})
		.catch((err) => {
			console.log(err)
			this.isLoading = false;
			if(!this.loadingGigs){
				NProgress.done();
			}
		})

		this.$store.dispatch('bankHolidays/getBankHolidays')


		var query = window.matchMedia("(max-width:768px)");

		if (query.matches){
			this.mobile = true;
		} else {
			this.mobile = false;
		}

		if (!this.mobile) {
			const tooltip = document.querySelector('#tooltip');

			const calendarWrapper = document.getElementById('calendarWrapper');
			calendarWrapper.addEventListener('mouseover', (e) => {
				const el = e.target;
				const schedule = el.closest('.tui-full-calendar-weekday-schedule');


				if (schedule) {
					let scheduleID = schedule.getAttribute('data-schedule-id');
					let gigObj = this.gigs.find(gig => gig.id == scheduleID);

					this.$refs.calendarhover.isArtistView = false;
					this.$refs.calendarhover.item = gigObj;
					this.$refs.calendarhover.tooltip = true;

				} else {
					this.$refs.calendarhover.tooltip = false;
				}

				Object.assign(tooltip.style, {
				top: `${event.pageY - 220}px`,
				left: `${event.pageX - 260}px`,
				});

				if (event.pageY < 400){
					Object.assign(tooltip.style, {
						top: `${event.pageY}px`,
					});
				} if (event.pageY > 750){
					Object.assign(tooltip.style, {
						top: `${event.pageY - 220}px`,
					});
				} if (event.pageX > 840){
					Object.assign(tooltip.style, {
					left: `${event.pageX - 700}px`
					});
				} if (event.pageX < 494){
					Object.assign(tooltip.style, {
					left: `${event.pageX - 260}px`
					});
				} 
			});
		}
	}
}
</script>