<template>
    <div>
 
        <SpecialityModal ref="specialityModal" v-on:onPickedSelections="addSpeciality($event)" v-on:onUpdatedSelections="updateSpeciality($event)" /> 
        <GigLocationsModal ref="locationsModal" v-on:onPickedLocations="addGigLocations($event)" v-on:onUpdatedLocations="updateGigLocation($event)"/>

        <div class="details-view mb-36">
          <h3>{{this.$attrs.artistId ? 'Edit' : 'Add'}} your artist profile here</h3>
          <div>
              <div class=" lg:max-w-5xl">
                  <InlineNotification Heading="Create your artist profile" class="p-0 m-0" v-if="!this.$attrs.artistId">
                      <template slot="body">

                        <DictionaryContent  :hookId="14" dynamicUrl="artist/profiles">
                          <p>It’s showtime. Add everything that makes your act the one to book. You’ll show up in all venue searches in the region, and you’ll officially become a GigPig artist.  </p>
                     
                        </DictionaryContent>

                      </template>
                  </InlineNotification>
              </div>
          </div>

          <div class="mb-6 form input-group lg:max-w-5xl" ref="form" novalidate>
              <div class="mt-6 sm:mt-5 col-span-full">
                <h4 class="uppercase text-lg leading-6 font-bold text-gray-900">
                  Artist info
                </h4>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  Venues will be able to see all this info on your artist profile page.
                </p>
              </div>

            <div class="mt-6 sm:mt-5  col-span-full space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="artist-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Artist / Band name
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <InputText id="artist-name" required v-on:input="form.name = $event;" v-bind:value="form.name" hint="The artist/band name must be populated"
                error="The artist/band name must be completed" name="artist-name"
                />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="artist-telephone"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Mobile
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <InputText id="artist-telephone" required v-on:input="form.telephone = $event;" v-bind:value="form.telephone" type="tel"
                error="The artist mobile must be completed" name="artist-telephone" class="max-w-xs"
                />
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                for="artist-telephone"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Email address
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <InputText id="artist-email" required v-on:input="form.email = $event;" v-bind:value="form.email" type="email"
                error="The artist email must be completed" name="artist-email" class="max-w-xs"
                />
              </div>
            </div>              
          </div>

          <Accordian heading="Minimum Fee" :toggle="true" bg_class="bg-gp_pink-default-300" class="mt-6 sm:mt-5  col-span-full space-y-6 sm:space-y-5">
            <template #contents>
              <InputMinFees
                ref="minfees"
                v-on:updatedDailyFees="updateDailyFees($event)"
                v-on:updatedSpecialFees="updateSpecialFees($event)"
              />
            </template>
          </Accordian>

          <div class="mt-6 sm:mt-5 col-span-full sm:border-t sm:border-gray-200 sm:pt-5">
            <h4 class="uppercase text-lg leading-6 font-bold text-gray-900">
              Matching you with the right gig - artist type
            </h4>
            <div class="mt-4 max-w-2xl text-sm text-gray-500"> 
              <p >
                We’ll use the info here to get the best gigs for you.  
              </p>
              <p class="mt-4 text-gp_pink-default-500">Please note, if you are a guitarist/pianist vocalist, you need to select "solo singer"</p>
            </div>
          </div>
  
          <div class="grid grid-flow-row gap-4 md:grid-flow-col items-baseline w-full col-span-full justify-between">       
            <div v-if="specialities && specialities.length == 0">
            <TRichSelect 
                            id="typeSelection" 
                            ref="typeSelection" 
                            name="type-selection" 
                            class="trichselect w-full w-96" 
                            :options="tiers" v-model="tier_id"
                            value-attribute="id"  @change="changeTier()" 
                            placeholder="Choose a type of artist"
                            text-attribute="name"                             
                          ></TRichSelect>
            </div>
            <div v-else>
              {{form.tier.name}} <br/> <span class="text-sm italic text-gray-400">Remove your current specialities to change your artist type.</span>
            </div>
            <Button class="w-full" @click.native="openSpecialityModal()" :class="speciality_button_css">
              Add a new specialism
            </Button>
          </div>
          <div class="space-y-6  col-span-full sm:space-y-5">
            <div class="">
              <div class=" sm:col-span-2  grid grid-flow-row gap-y-4">
                <div v-if="specialities && specialities.length > 0" class=" shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="table-auto lg:table-fixed min-w-full divide-y divide-gray-200 text-left" >
                    <thead class="bg-gray-50 text-base">
                      <tr class="hidden sm:table-row">
                        <th scope="col" class="px-4 py-2">
                          Type of Entertainer
                        </th>
                        <th scope="col" class="p-2">
                          More info
                        </th>
                        <th scope="col" class="p-2">
                          Covers / Originals
                        </th>
                        <th scope="col" class="p-2">
                          Vocals
                        </th>
                        <th scope="col" class="p-2">
                          Genres
                        </th>
                        <th scope="col" class="p-2">  
                        </th>      
                      </tr>
                    </thead>
                    <tr v-show="showSpecials" class="text-base sm:block grid grid-cols-4 sm:grid-cols-none sm:table-row " v-for="(item, index) in specialities" :key="index">
                      <td class="p-2   col-span-4 sm:col-auto"> 
                        {{ parseTier(tier_id)}}                            
                      </td> 
                      <td class="p-2  col-span-4 sm:col-auto">
                        <div class="grid grid-flow-col gap-2 justify-start" v-if=item.more_info>
                          <div v-for="(tag, index) in item.more_info" v-html="parseTag(tag)" :key="'more_info_' + index">
                          </div>
                          <div v-if="!item.more_info.length" class="hidden sm:block">N/A</div>
                        </div>
                      </td>
                      <td class="p-2 col-span-1 sm:col-auto">
                        <div v-for="(tag, index) in item.covers" v-html="parseTag(tag)" :key="'covers_' + index">
                        </div>
                        <div  v-if="!item.covers.length" class="hidden sm:block">N/A</div>
                      </td>
                      <td class="p-2 col-span-1 sm:col-auto">
                        <div v-for="(tag, index) in item.vocals" v-html="parseTag(tag)" :key="'vocals_' + index">
                        </div>
                        <div  v-if="!item.vocals.length" class="hidden sm:block">N/A</div>
                      </td>
                      <td class="p-2 col-span-1 sm:col-auto">
                        <div v-for="(tag, index) in item.genres" v-html="parseTag(tag)" :key="'genres_' + index">
                        </div>
                        <div   v-if="!item.genres.length" class="hidden sm:block">-</div>
                      </td>
                      <td class="col-span-4 bg-gp_pink-default-300 text-gp_pink-default-500 sm:bg-transparent space-x-2">
                        <div class="flex space-x-6 my-3 mr-3 justify-end">
                        <PencilIcon class="cursor-pointer" @click="editSpeciality(index)" />
                        <TrashIcon  class="cursor-pointer" @click="deleteSpeciality(index)" />
                        </div>
                      </td>
                    </tr>
                </table>
              </div>
              <div v-if="specialities && specialities.length == 0">
                <NoDataMsgWrapper>Please add at least one speciality.</NoDataMsgWrapper>
              </div>
            </div>
          </div>
        </div>


          <!-- Geo Locator-->
          <div class="mt-6 sm:mt-5 col-span-full sm:border-t sm:border-gray-200 sm:pt-5">
            <h4 class="uppercase text-lg leading-6 font-bold text-gray-900">
              Matching you with the right gig - locations
            </h4>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              We’ll use the info here to get the best gigs for you.
            </p>
          </div>

           <div class="grid grid-flow-row gap-4 md:grid-flow-col items-baseline w-full col-span-full justify-between">
            <p class="mt-8 text-md text-gray-700">I'd like to perform in the following locations, you may select multiple:</p>
            <Button @click.native="openLocationsModal()" class="w-full">
              Add a new location
            </Button>
          </div>

         <div class="space-y-6 col-span-full sm:space-y-5">
            <div class="">
              <div v-if="this.locations.length > 0" class="sm:col-span-2  grid grid-flow-row gap-y-8">
                <div v-for="(location, index) in this.locations"  :key="'location' + index" @click="tableIndex = index" class=" shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="table-auto lg:table-fixed min-w-full divide-y divide-gray-200 text-left" >
                    <thead class="bg-gray-50 text-base hover:cursor-pointer">
                      <tr class="sm:table-row">
                        <th scope="col" class="p-2">
                          <div class="flex space-x-2">
                            <span>{{ location.country_name }}</span>
                            <span class="text-gray-500">-</span>
                            <span>{{ location.region }}</span>
                          </div>
                        </th>
                        <th scope="col" class="p-2 space-x-4 flex justify-end text-gp_pink-default-500 items-center">
                          <span v-if="index == tableIndex" class="uppercase text-xs text-gray-500 mr-11 hidden sm:block">Selected</span>
                          <span v-else class="uppercase text-xs text-gray-500 mr-11 hidden sm:block">Click to view locations</span>
                          <PencilIcon @click="editGigLocation(index)" class="cursor-pointer" />
                          <TrashIcon @click="removeGigLocation(index)" class="cursor-pointer" />
                        </th>
                      </tr>
                    </thead>
                    <tr v-show="index == tableIndex" class="text-base sm:block  sm:grid-cols-none sm:table-row overflow-auto">
                      <td class="p-2 col-span-4 sm:col-4 space-y-4">
                        <p v-show="location.areas.length > 0" class="text-indigo-500">Areas:</p>
                        <div class="text-sm justify-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 text-align w-">
                          <span v-for="(area, index) in location.areas" :key="'areas_' + index">
                            {{ area }}
                          </span>
                        </div>
                        <p v-show="location.places.length > 0" class="text-indigo-500">Locations:</p>
                        <div class="text-sm justify-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 text-align w-" style="text-align: left">
                          <span v-for="(place, index) in location.places" :key="'places_' + index">
                            {{ place }}
                          </span>
                        </div>
                      </td>
                    </tr>
                </table>
                </div>
              </div>
            <div>
            <NoDataMsgWrapper v-show="this.locations.length == 0">Please add at least one gig location.</NoDataMsgWrapper>
          </div>
        </div>
      </div>



            

        <!-- Profile Contact Details -->
        <Accordian v-show="tier_id" heading="Profile information" :toggle="true" bg_class="bg-gp_pink-default-300" class="mt-6 sm:mt-5  col-span-full space-y-6 sm:space-y-5">
            <template #contents>
            <InlineNotification Heading="GigPig Tip" class="p-0 m-0 mb-5">
                      <template slot="body">
                      <p class="text-justify">Artists that complete all of the fields listed below yield a higher gig offer rate. Be sure to upload your best content and that which most represents yourself as an artist.</p>
                      </template>
                  </InlineNotification>
            <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="artist-telephone"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Your bio
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputTextArea id="artist-description" required v-on:input="form.description = $event;" v-bind:value="form.description"
                       error="Your bio must be completed" name="artist-description" rows="6"
                        />

                </div>
              </div>
            
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="artist-telephone" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Last 5 venues you played
              </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div v-for="(played_venues, index) in form.played_venues" :key="index">
                    <div class="flex items-center mt-1">
                      <InputText
                        name="played_venues[]"
                        class="flex-1 mt-0"
                        v-model="form.played_venues[index]"
                      />
                      <button class="ml-3" type="button" @click="deleteVenue(index)">
                        <XIcon /> <span class="sr-only">Delete</span>
                      </button>
                    </div>
                  </div> 
                  <button 
                    type="button" 
                    @click="addVenue" 
                    :disabled="form.played_venues && form.played_venues.length >= 5"
                    class="flex items-center mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                  >
                    <PlusIcon fill="#fff" /> Add venue
                  </button>
                </div>
            </div>

            <div v-show="tier_id && !excludes.songDisplayTiers.includes(tier_id)"
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="artist-telephone"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Your top 5 songs you perform
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div v-for="(song_list, index) in form.song_list" :key="index">
                    <div class="flex items-center mt-1">
                      <InputText
                        name="song_list[]"
                        class="flex-1 mt-0"
                        v-model="form.song_list[index]"
                      />
                      <button class="ml-3" type="button" @click="deleteSong(index)">
                        <XIcon /> <span class="sr-only">Delete</span>
                      </button>
                    </div>
                  </div> 
                  <button 
                    type="button" 
                    @click="addSong" 
                    :disabled="form.song_list && form.song_list.length >= 5"
                    class="flex items-center mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                  >
                    <PlusIcon fill="#fff" /> Add song
                  </button>
                </div>
              </div>

              <div
                class="sm:grid input-group sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="photo"
                  class="block text-sm font-medium text-gray-700"
                >
                  Artist profile picture
                </label>
                <div class="input-group mt-1 sm:mt-0 sm:col-span-2">                 
                  <profile-picture-upload :image="image" v-on:onImageChange="profilePictureUploaded"/>
                </div>
              </div>

            <div class="mt-6 sm:mt-5 col-span-full">
              <h4 class="text-lg leading-6 font-bold text-gray-900">
                Gallery
              </h4>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Upload photos for your artist profile page (4mb limit, jpg or png).
              </p>
            </div>
              <div
                class="input-group sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="cover-photo"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Gallery
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <upload-gallery 
                    :images="gallery"
                    v-on:onGalleryChange="galleryChanged" 
                  />
                </div>
              </div>

            <div v-show="tier_id && !excludes.videoDisplayTiers.includes(tier_id)">
              <div class="input-group mt-6 sm:mt-5 col-span-full">
                <h4 class="text-lg leading-6 font-bold text-gray-900">
                  Videos
                </h4>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  Add videos from YouTube, Facebook, Vimeo, Dailymotion or Instagram reels. Paste the URL into the box below. 
                </p>

              </div>
              <div class="grid">
                <div class="mt-1 sm:mt-0 col-span-full">
                  <div class="grid grid-flow-row lg:grid-flow-col lg:grid-cols-3 gap-8">
                    <div v-for="(video, index) in 3" :key="index">
                      <video-embed :ref="'video_' + index" :src="form.videos ? form.videos[index] : ''"></video-embed>

                      <iframe v-if="form.videos && form.videos[index] && (form.videos[index].includes('instagram.com/') || form.videos[index].includes('facebook.com/'))" :src="form.videos[index]" scrolling="no" height="150" width="300"  />

                      <InputText
                                  name="video_0"
                                  v-model="form.videos[index]"
                                  v-on:input="form.videos[index] = $event;changeVideo($event, index); "
                                  v-bind:value="form.videos[index]"
                                  placeholder="Video e.g. https://www.youtube.com/watch?abcde" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div v-show="tier_id && !excludes.audioDisplayTiers.includes(tier_id)">
            <div class="input-group mt-6 sm:mt-5 col-span-full">
              <h4 class="text-lg leading-6 font-bold text-gray-900">
                Audio
              </h4>

              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Add audio from SoundCloud or Mixcloud. Paste the URL into the box below. 
              </p>
            </div>
			<div 
				class="grid">
				<div
					class="mt-1 sm:mt-0 col-span-full">
					<div
						class="grid grid-flow-row lg:grid-flow-col lg:grid-cols-3 gap-8">
						<div
							v-for="(audio, index) in 3"
							:key="index">
							<iframe
								width="100%" title="audio"	:ref="'audio_' + index">
							</iframe>
							<InputText
								name="audio_0" data-pristine-audiourl
								v-model="form.audio[index]" v-on:input="form.audio[index] = $event;updateAudio($event, index)"
								placeholder="Audio e.g. https://www.soundcloud.com/" />
						</div>
					</div>
				</div>
			</div>
    </div>


    </template>
</Accordian>

<div class="mt-6 sm:mt-5 space-y-6  col-span-full sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <span>Your home address</span>
              </div>
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label
                  for="artist-address-line1"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Line 1
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                       <InputText required id="artist-address-line1" v-on:input="form.address.address_line_1 = $event;" v-bind:value="form.address.address_line_1"
                       error="Artist address line one must be provided" name="artist-address-line1" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-line2"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Line 2 (Optional)
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="artist-address-line2"
                    v-model="form.address.address_line_2"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="artist-address-town"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Town/City
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                       <InputText required id="venue-address-town" v-on:input="form.address.town = $event;" v-bind:value="form.address.town"
                       error="The artist town must be provided" name="artist-address-town" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-county"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  County
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">


                       <InputText required id="venue-address-county" v-on:input="form.address.county = $event;" v-bind:value="form.address.county"
                       error="The artist county must be provided" name="artist-address-county" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>

                <div
                        class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
                >
                    <label
                            for="countries"
                            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                        Country
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2 pt-2">
                        <TRichSelect
                                id="countries"
                                ref="countriespicker"
                                name="countries"
                                v-model="form.address.country_id"
                                @change="pickCountry"
                                :options="countries"
                                value-attribute="id"
                                text-attribute="name"
                                class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        />

                    </div>
                </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="venue-address-postcode"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Postcode
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">

                       <InputText required id="venue-address-postcode" v-on:input="form.address.post_code = $event;" v-bind:value="form.address.post_code"
                       error="Your artist address must be provided" name="artist-address-postcode" class="max-w-lg sm:max-w-xs"
                        />
                </div>
              </div>

<Accordian heading="Invoice / Billing address" :toggle="true" bg_class="bg-gp_pink-default-300" class="mt-6 sm:mt-5  col-span-full space-y-6 sm:space-y-5">
    <template #contents>

      <div class="mt-6 sm:mt-5  col-span-full">
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Add your invoice info, so venues can pay you directly.
        </p>
      </div>
        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
        >
          <label
            for="venue-address-postcode"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Account Name
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">

                <InputText required id="account_name" v-on:input="form.account_name = $event;" v-bind:value="form.account_name"
                error="Your bank account name must be provided" name="account_name" class="max-w-lg sm:max-w-xs"
                  />

          </div>
        </div>

      <div v-show="!form.iban_number" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
        <label for="venue-address-postcode" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Account number
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <InputText id="account_name" v-on:input="form.account_number = $event;" v-bind:value="form.account_number" maxlength="8"
            error="Your bank account number must be provided" name="account_name" class="max-w-lg sm:max-w-xs" placeholder="8-digit number (e.g. 12345678)"
          />
        </div>
      </div>

      <div v-show="!form.iban_number" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
        <label for="venue-address-postcode" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Sort code
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <InputText id="account_sortcode" v-on:input="form.sortcode = $event;" v-bind:value="form.sortcode" maxlength="6"
            error="Your bank sort code must be provided" name="account_sortcode" class="max-w-lg sm:max-w-xs" placeholder="6-digit number (e.g. 123456)"
          />
        </div>
      </div>

      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
        <label for="account_iban_number" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          IBAN Number
            <span class="text-xs font-light">(Optional)</span>
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <InputText id="account_iban_number" v-on:input="form.iban_number = $event;" v-bind:value="form.iban_number"
            name="account_iban_number" class="max-w-lg sm:max-w-xs" maxlength="34"
          />
        </div>
      </div>

        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0 mt-1"
        >
          <label
            for="venue-address-postcode"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            My billing address is the same as my home address
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <t-toggle v-model="billing_same_as"></t-toggle>
          </div>
        </div>
      <div class="mt-6 sm:mt-5 space-y-6  col-span-full sm:space-y-5" v-if="!billing_same_as">
        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
        >
          <span>Billing address</span>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
          <label
            for="venue-billing-address-line1"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
          Billing Line 1
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">



                <InputText required id="artist-billing-address-line1" v-on:input="form.billing_address.address_line_1 = $event;" v-bind:value="form.billing_address.address_line_1"
                error="The artist billing address line one must be provided" name="artist-billing-address-line1" class="max-w-lg sm:max-w-xs"
                  />
          </div>
        </div>
        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
        >
          <label
            for="venue-address-line2"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
          Billing Line 2 (Optional)
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              name="venue-billing-address-line2"
              id="venue-billing-address-line2"
              v-model="form.billing_address.address_line_2"
              class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
        >
          <label
            for="venue-billing-address-town"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
          Billing Town/City
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">


                <InputText id="venue-billing-address-town" v-on:input="form.billing_address.town = $event;" v-bind:value="form.billing_address.town"
                error="The venue's billing town must be provided" name="venue-billing-address-town" class="max-w-lg sm:max-w-xs"
                  />
          </div>
        </div>
        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
        >
          <label
            for="venue-billing-address-county"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
          Billing County
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">


                <InputText id="venue-billing-address-county" v-on:input="form.billing_address.county = $event;" v-bind:value="form.billing_address.county"
                error="The venue's billing county must be provided" name="venue-billing-address-county" class="max-w-lg sm:max-w-xs"
                  />
          </div>
        </div>
        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
        >
          <label
            for="venue-address-postcode"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Billing Postcode
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">

                <InputText id="venue-billing-address-postcode" v-on:input="form.billing_address.post_code = $event;" v-bind:value="form.billing_address.post_code"
                error="The venue's billing postcode must be provided" name="venue-billing-address-postcode" class="max-w-lg sm:max-w-xs"
                  />
          </div>
        </div>
      </div>

        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
        >
          <label
            for="venue-address-postcode"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Unique Taxpayer Reference (UTR)*
            <span class="block text-xs italic">*Optional unless venue requested</span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <InputText
              id="account_utr" 
              v-on:input="form.utr_number = $event;" 
              v-bind:value="form.utr_number"
              name="account_utr" 
              class="max-w-lg sm:max-w-xs"
              placeholder="UTR 12345 67890"
              maxlength="10"
            />
          </div>
        </div>

        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0"
        >
          <label
            for="venue-address-postcode"
            class="block flex  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            <t-toggle v-model="show_vat_field"></t-toggle>
            <span class="ml-3">VAT registered</span>
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <InputText
              v-if="show_vat_field"
              id="account_vat" 
              v-on:input="form.vat_number = $event;" 
              v-bind:value="form.vat_number"
              name="account_vat" 
              class="max-w-lg sm:max-w-xs"
              placeholder="GB123456789"
            />
          </div>
        </div>
      </template>
  </Accordian>
                <div v-if="showChooseVenues" class="grid grid-flow-row gap-4 md:grid-flow-col items-baseline w-full col-span-full justify-between sm:border-t sm:border-gray-200 pt-5">
                    <div>
                        Private Venues <br/> <span class="text-sm italic text-gray-400">Choose which private venue requests to accept or decline.</span>
                    </div>
                    <GPButton
                            class="w-full"
                            @click.native="openPrivateVenuesOptInModal()"
                            :class="speciality_button_css"
                            :disabled="chooseVenueBtnDisabled"
                            :loading="chooseVenueBtnDisabled"
                    >
                        Choose Venues
                    </GPButton>
                </div>

                <div class="grid grid-flow-row gap-4 md:grid-flow-col items-baseline w-full col-span-full justify-between sm:border-t sm:border-gray-200 pt-5">
                    <div>
                        Profile Status <br/>

                        <span class="text-sm italic text-gray-400">Pausing the profile will mean that this artist profile will not be included in any gig offers or artist searches available to the venue.</span>

                        <Transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">
                            <span v-if="!active" class="text-sm italic text-red-400"><br>You will no longer receive gig offers and your profile will not appear in artist searches made by venues.</span>
                        </Transition>


                    </div>
                    <label
                            for="venue-address-postcode"
                            class="block flex  text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                        <t-toggle v-model="active"></t-toggle>
                        <span class="ml-3">{{ active ? 'Active' : 'Paused'}}</span>
                    </label>
                </div>

        </div>

      </div>
      <div class="pt-5 max-w-5xl col-span-full sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-0">
        <div class="flex justify-end col-span-2">
          <GPButton
            @click.native="save()"
            :disabled="btnDisabled"
            type="submit"
            class="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          {{submitting == true ? 'Submitting... please wait' : 'Save' }}
          </GPButton>
        </div>
      </div>
    </div>
        <NotificationSmall ref="notification" />
        <PrivateVenuesOptInModal
                :show-modal="showPrivateVenuesModal"
                @showPrivateVenuesModal="value => showPrivateVenuesModal = value"
                :venue-requests-prop="privateVenueRequests"
        />
    </div>
    
</template>

<script type="text/javascript">
import GPButton from '../../components/ui/buttons/Button.vue';
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';
import Button from '../../components/ui/buttons/Button.vue'
import Pristine from '../../utils/pristine.js' 
import Accordian from '../../components/accordian/Accordian.vue' 
import appConfig from "@/app.config";
import { apiComputed, authComputed, apiMethods } from "@/state/helpers";
import InputTextArea from '../../components/form/InputTextArea.vue'
import InputText from '../../components/form/InputText.vue'
import NotificationSmall from '../../components/notifications/small.vue';
import changeAudio from  '@/utils/change-audio.js'
import InputMinFees from '../../components/form/InputMinFees.vue'
import InlineNotification from '../../components/notifications/inline.vue'
import {
  TToggle, TRichSelect
} from 'vue-tailwind/dist/components'
import UploadGallery from '../../components/uploads/upload-gallery.vue'
import ProfilePictureUpload from '../../components/uploads/profile-picture-upload.vue'
import { PlusIcon, XIcon, TrashIcon, PencilIcon  } from '@vue-hero-icons/outline'
import SpecialityModal from '../../components/modal/ArtistSpeciality.vue'; 
import GigLocationsModal from '../../components/modal/ArtistGigLocations.vue';
import PrivateVenuesOptInModal from "@/components/PrivateVenueOptIn/PrivateVenuesOptInModal.vue";
import client from '@/utils/client.js'
import DictionaryContent from '../../components/dictionary/DictionaryContent.vue';

export default
{
    name: "AddEditArtist",
    components:{
    GPButton,
    InlineNotification,
    InputText,
    InputTextArea,
    InputMinFees,
    TToggle,
    TRichSelect,
    NotificationSmall,
    Accordian,
    UploadGallery,
    ProfilePictureUpload,
    PlusIcon,
    XIcon,
    TrashIcon, PencilIcon, Button,
    SpecialityModal,
    GigLocationsModal,
    PrivateVenuesOptInModal,
    NoDataMsgWrapper,
    DictionaryContent
},
    created() {
      if (this.$attrs.artist === undefined || this.$attrs.artist.paused === undefined) {
        this.active = true
      } else {
        this.active = !this.$attrs.artist.paused;
      }


      this.getPrivateVenueRequestsByArtist(this.$attrs.artistId).then((response) => {
          this.privateVenueRequests = response.data.data
      })
    },
    async mounted(){

      client.get('countries').then(response => (this.countries = response.data.data))

      client.get('holidays').then(response => { 
        this.$refs.minfees.holidays = JSON.parse(JSON.stringify(response.data));
        let resp = response.data;
        resp.forEach((e) => { e.fee = null });
        this.$refs.minfees.specialFees = resp;
      });


      await this.getArtistSignupExcludables()
        .then((resp) => {
          this.excludes = resp;
        });

      await this.getGigLocations().then((resp) =>{
        this.$refs.locationsModal.itemCopy = JSON.parse(JSON.stringify(resp));
        this.$refs.locationsModal.item = JSON.parse(JSON.stringify(resp));
        this.locations_list = resp;
      })

      if (!this.tiers){
       await this.getTiers().then((resp) => {
          this.tiers = resp.data;
        }); 
      }

 
      Pristine.addValidator('audiourl', function(value) {
      
      try
      {
         new URL(value);  
      }
      catch(_){
        return true;
      }
      
			let url = new URL(value);

      if (!url.valid)
        return true;
			
      return url.host === 'soundcloud.com' || url.host === 'on.soundcloud.com' || url.host === 'www.soundcloud.com' || url.host === 'mixcloud.com' || url.host === 'www.mixcloud.com'
		}, "A valid Soundcloud or Mixcloud URL is required (including https://)", 1, false)


        this.pristine = new Pristine(this.$refs.form, this.pristineConfig);
 
        let artist = this.$attrs.artist;
 
        if (this.$attrs.artistId)
        {  
            this.populateForm(artist);
            this.formatLocations();
        }
        else{
          this.speciality_button_css = "opacity-20 cursor-not-allowed";
        }
    },
    computed: {
        ...apiComputed,
        ...authComputed,
        btnDisabled(){
          return this.submitting;
        },
        showChooseVenues() {
            return this.privateVenueRequests.length > 0
        },
        chooseVenueBtnDisabled() {
            return this.showPrivateVenuesModal
        }
    },
    watch: {
        specialities() {
            this.specialitiesToDelete = this.specialitiesExiting.map(i => {
                return i.id
            }).filter(id => {
                return this.specialities.map(iv => {
                    return iv.id
                }).indexOf(id) === -1
            })

            this.specialitiesToAdd = this.specialities.filter(item => {
              return !Object.hasOwn(item, 'id')
            })
        },
    },
    methods: {
      updateDailyFees(value){
        this.dailyFees = value;
      },
      updateSpecialFees(value){
        this.specialFees = value;
      },
      pickCountry(value){
          this.form.address.country_id = value
      },
      formatLocations(){

        let region = [];
        let areas = [];
        let places = [];

        region = this.form.gig_locations.filter((f) => {
          return f.parent_id == null;
        })

        region.filter((r) => {
          areas.push(this.form.gig_locations.filter((f) => {
            return f.parent_id == r.id
          }))
        });

        areas.filter((c) => {
          c.filter((r) => {
            places.push(this.form.gig_locations.filter((f) => {
              return f.parent_id == r.id
            }))
          })
        });

        // Creates parent child hierarchy with the above data
        region.filter((region) => {

          this.grouped_locations.region = region.name;
          this.grouped_locations.ids.push(region.id);
          this.grouped_locations.country_id = region.country_id;
          this.grouped_locations.country_name = region.country.name;

          areas.map((cityMap) => {
            cityMap.filter((city) => {

              if(city.parent_id == region.id){
                this.grouped_locations.areas.push(city.name);
                this.grouped_locations.ids.push(city.id);

                places.map((townMap) => {
                  townMap.filter((town) => {

                    if (town.parent_id == city.id) {
                      this.grouped_locations.places.push(town.name);
                      this.grouped_locations.ids.push(town.id);
                    }
                  })
                })
              }
            })
          })

          let locationSet = {};
          Object.assign(locationSet, this.grouped_locations);
          this.locations.push(locationSet);

          this.grouped_locations.areas = [];
          this.grouped_locations.places = [];
          this.grouped_locations.ids = [];

          let countryIndex = this.locations_list.findIndex((f) => f.id === region.country_id);
          let regionIndex = this.locations_list[countryIndex].locations.findIndex((f) => f.id === region.id);
          this.locations_list[countryIndex].locations.splice(regionIndex, 1);
        });

        this.$refs.locationsModal.item = this.locations_list;
      },
      editSpeciality($speciality_index){
        let speciality = this.specialities[$speciality_index];
        this.trackSpecialityIndex = $speciality_index;
        this.$refs.specialityModal.updateSpecialities(speciality);
      },
      updateSpeciality($speciality){
        this.showSpecials = false;
        this.specialities.splice(this.trackSpecialityIndex, 1);
        this.specialities.push($speciality); 
        this.showSpecials = true;
      },
      deleteSpeciality($speciality_index){

        let speciality = this.specialities[$speciality_index];

        console.log("removing speciality", {speciality});

        if (speciality.id){
          client.delete('/artists/' + this.form.id + '/specialities/' + speciality.id)
        }

        this.specialities.splice($speciality_index, 1);
      },
      addSpeciality($speciality){
          console.log('$speciality', $speciality)
        this.specialities.push($speciality); 
      },
      addGigLocations($locations){
        this.$refs.notification.createNotification('Locations successfully added!', false);
        this.locations.push($locations);
      },
      removeGigLocation($location_index){
        this.$refs.locationsModal.addRegionBackToList(this.locations[$location_index].ids[0], this.locations[$location_index].country_id);
        this.$refs.notification.createNotification('Locations successfully removed!', false);
        this.locations.splice($location_index, 1);
      },
      editGigLocation($location_index){
        this.locationIndexTracker = $location_index;
        this.$refs.locationsModal.addRegionBackToList(this.locations[$location_index].ids[0], this.locations[$location_index].country_id);
        this.$refs.locationsModal.updateLocation(this.locations[$location_index].ids, this.locations[$location_index].country_id);
      },
      updateGigLocation($locations){
        this.locations.splice(this.locationIndexTracker, 1, $locations);
        this.$refs.notification.createNotification('Locations successfully updated!', false);
      },
      changeTier(){
        this.selections.tier = this.tiers.find(t => t.id == this.tier_id);
        this.form.tier = this.selections.tier;
        this.speciality_button_css = '';
      },
      parseTier(){
        return this.tiers.find(t => t.id == this.tier_id).name;
      },
      parseTag($tag){
        let selectedTier = this.selections.tier;
        let possible_tags = [...selectedTier.more_info];
        
        possible_tags.push(
          ...selectedTier.covers,
          ...selectedTier.vocals,
          ...selectedTier.genres,
        );

        

        if (Number.isInteger($tag))
        {          
            $tag =  possible_tags.find(f => f.id == $tag);
        }        
 
        return $tag ? $tag.name : $tag;

      },
      openSpecialityModal(){
        this.$refs.specialityModal.tier = this.tiers.find(t => t.id == this.tier_id);
        this.$refs.specialityModal.toggleModal();
      },
      openLocationsModal(){
        this.$refs.locationsModal.toggleModal();
      },
      openPrivateVenuesOptInModal() {
          this.showPrivateVenuesModal = true
      },
      changeAudio, 
        ...apiMethods,
        populateForm(artist){

            if (artist.min_fees.length) {
              this.dailyFees = artist.min_fees;
              this.$refs.minfees.dailyFees = artist.min_fees;
            }

            if (artist.min_fees_holidays.length) {
              this.$refs.minfees.updateSpecials(artist.min_fees_holidays);
            }

            this.form = artist; 
            this.image = artist.image;

            this.gallery = artist.gallery;

            this.tier_id = artist.tier.id; 

            this.selections.tier = this.tiers.find(t => t.id == this.tier_id);
            this.$refs.specialityModal.tier = this.selections.tier;


            this.form.song_list = artist.song_list === null ? [] : artist.song_list; 

            if (artist.specialities){
              artist.specialities.map((f) => {
                let specialism = {
                  id: f.id,
                  more_info: [],
                  covers: [],
                  genres: [],
                  vocals: []
                }

                specialism.more_info = f.more_info.map((e) => { return e.id });
                specialism.covers = f.covers.map((e) => { return e.id });
                specialism.genres = f.genres.map((e) => { return e.id });
                specialism.vocals = f.vocals.map((e) => { return e.id });

                this.specialities.push(specialism);
              })

              this.specialitiesExiting = JSON.parse(JSON.stringify(this.specialities));
            } 
 
            let hasSetAddress = (artist.billing_address != null && artist.address != null)
            let addressesMatch = false;

            addressesMatch = hasSetAddress && (artist.billing_address.address_line_1 + artist.billing_address.post_code == artist.billing_address.address_line_1 + artist.address.post_code);

            this.billing_same_as = !hasSetAddress || addressesMatch;

            if (!artist.videos){ 
              this.form.videos = ["", "", ""]
            }
            else{
              artist.videos.forEach(function(v, i) {
 
                  if (v == "null")
                  {
                    artist.videos[i] = null;
                  }
              });
            } 

            if (!artist.audio){ 
              this.form.audio = ["", "", ""]
            }
            else{
              artist.audio.forEach(function(a, i) { 
                  if (a == "null")
                  {
                    artist.audio[i] = null;
                  }
              })
            }

            if (artist.vat_number !== null) {
              this.show_vat_field = true
            }

            if (!artist.address)
            {

                this.form.address = {
                    address_line_1: "",
                    address_line_2: "",
                    town: "",
                    county: "",
                    post_code: "",
                    country_id: null
                };

            }

            if (!artist.billing_address)
            {
                this.form.billing_address = {
                    address_line_1: "",
                    address_line_2: "",
                    town: "",
                    county: "",
                    post_code: "",
                };
            }

            this.billing_same_as = true
        },
        galleryChanged(images) {
          this.gallery = images
        },
        profilePictureUploaded(image) {
          this.image = image
        },
        addSong() {
          this.form.song_list = [...this.form.song_list, '']
        },
        addVenue() {
          this.form.played_venues = [...this.form.played_venues, '']
        },
        deleteSong(songIndex) {
          this.form.song_list = this.form.song_list.filter((item, index) => index !== songIndex)
        },
        deleteVenue(venueIndex) {
          this.form.played_venues = this.form.played_venues.filter((item, index) => index !== venueIndex)
        },
        parseError(response){
 
          let data = response.errors;
          let error = "";
          Object.keys(data).forEach(function(key) {
            error += ' ' + data[key];
          });

          return error;
        },
        save(){


            let locationIDs = [];
            this.locations.map((f) => { locationIDs.push(...f.ids); })

            this.submitting = true;
            const self = this;
            const artistId = this.form.id

            if (!this.form.name)
            {
                this.$refs.notification.createNotification('You must choose an artist/band name', true);
                this.submitting = false;
                return;
            }

            if (this.locations.length === 0)
            {
                this.$refs.notification.createNotification('You must select at least one gig location', true);
                this.submitting = false;
                return;
            }

            if (!this.tier_id)
            {
                this.$refs.notification.createNotification('You must select an artist type', true);
                this.submitting = false;
                return;
            }
  
            if (!this.specialities || !this.specialities.length)
            {
                this.$refs.notification.createNotification('You must select at least one artist speciality', true);
                this.submitting = false;
                return;
            }

            if (!this.form.description && !this.excludes.bioTiers.includes(this.tier_id)){
                this.$refs.notification.createNotification('You must fill in the artist description', true);
                this.submitting = false;
                return;
            }

            if ((this.form.song_list.length === 0 && !this.excludes.songTiers.includes(this.tier_id)) || (this.form.song_list.length < 5 && !this.excludes.songTiers.includes(this.tier_id))){
                this.$refs.notification.createNotification('You must input the top five songs you perform', true);
                this.submitting = false;
                return;
            }


            if (this.image == null || this.image.uploading == true)
            {
                this.$refs.notification.createNotification('You must upload an artist picture', true);
                this.submitting = false;
                return;
            }

            let $user = this.$store.getters['user/userData'];

            if (!$user || $user.referral_code != "genre")
            {
              if ((this.form.videos.filter(fil => fil == "").length == 3 && !this.excludes.videoTiers.includes(this.tier_id))
                  && (this.form.audio.filter(fil => fil == "").length == 3 && !this.excludes.audioTiers.includes(this.tier_id)))
              {
                  this.$refs.notification.createNotification('You must have linked one video or audio source', true);
                  this.submitting = false;
                  return;
              }
            }

            if(!this.billing_same_as && (this.form.billing_address.address_line_1 == "" || this.form.billing_address.town == "" ||
              this.form.billing_address.county == "" || this.form.billing_address.post_code == ""))
            {
                this.$refs.notification.createNotification('You must complete your billing address details', true);
                this.submitting = false;
                return;
            }

            if (!this.form.iban_number && !this.form.account_number && !this.form.sortcode){
                this.$refs.notification.createNotification('You must complete your invoice address details', true);
                this.submitting = false;
                return;
            }

            if (!this.form.iban_number && this.form.account_number.length !== 8){
                this.$refs.notification.createNotification('Invalid account number', true);
                this.submitting = false;
                return;
            }

            if (!this.form.iban_number && (this.form.sortcode.length !== 6 || isNaN(this.form.sortcode))){
                this.$refs.notification.createNotification('Invalid sort code', true);
                this.submitting = false;
                return;
            }

            let valid = this.pristine.validate();

            if (valid && this.submitting)
            { 
 
                const payload = {
                  name: this.form.name,
                  email: this.form.email,
                  telephone: this.form.telephone,
                  description: this.form.description,
                  played_venues: this.form.played_venues,
                  song_list: this.form.song_list,
                  videos: this.form.videos ? this.form.videos : [],
                  audio: this.form.audio ? this.form.audio : [],
                  sortcode: this.form.sortcode,
                  iban_number: this.form.iban_number,
                  account_number: this.form.account_number,
                  utr_number: this.form.utr_number,
                  vat_number: this.form.vat_number,
                  account_name: this.form.account_name,
                  image: this.image !== null ? this.image.id : null,
                  tier_id: this.tier_id,
                  daily_fees: this.dailyFees,
                  special_fees: this.specialFees,
                  gallery: this.gallery.map(gallery => gallery.id),
                  address: this.form.address,
                  billing_same_as: this.billing_same_as,
                  billing_address: this.billing_same_as ? null : this.form.billing_address,
                  specialities: this.specialities,
                  locations: locationIDs,
                  paused: !this.active
                }

                if (!artistId) {
                  this.createArtist(payload).then((response) => {
                      if (response && response.status === 201) {
                          self.$notify({
                              title: 'Now previewing profile page',
                              text: 'Profile created'
                          });

                          self.$router.push({ name: "ArtistProfileView", params: { id: response.data.data.id } });
                          this.submitting = false;
                      } else {
                        this.submitting = false;
                        self.$refs.notification.createNotification("An error occurred on our end whilst creating this artist:" + self.parseError(response), true);
                        console.error("error sending payload to server", JSON.stringify(payload));
                      }
                  });
                }
                else {
									
                  let self = this;  

                  this.updateArtist({ artistId, artist: payload }).then(function(response){
                      if (response && response.status === 200) {

                        let specialityUpdates = [];

                        client.post('/artists/' + artistId + '/locations', {
                            locations : locationIDs
                        });

                        client.post('/artists/' + artistId + '/minfees', {
                            min_fees_days : self.dailyFees,
                            min_fees_holidays : self.specialFees
                        });

                          self.specialitiesToAdd.forEach(function(speciality){

                              specialityUpdates.push(client.post('/artists/' + artistId + '/specialities', {
                                tier : self.tier_id,
                                genres: speciality.genres,
                                more_info: speciality.more_info,
                                vocals: speciality.vocals,
                                covers: speciality.covers
                            }));
                          });

                        Promise.all(specialityUpdates).then(() => {

                            self.$notify({
                                title: 'Viewing profile page',
                                text: 'Profile updated'
                            });
                        }).finally(() => {

                          self.$router.push({ name: "ArtistProfileView", params: { id: response.data.data.id } });
                          self.submitting = false;
                        }).catch(() => {
                          self.$refs.notification.createNotification("An error occurred on our end whilst updating this artist's specialisms:" + self.parseError(response.message), true);    
                          self.submitting = false;                
                        }); 


                      } else {
                          self.$refs.notification.createNotification("An error occurred on our end whilst updating this artist:" + self.parseError(response), true);
                          self.submitting = false;
                      }
                  }).catch(function(error){
                    console.log("error: ", error);
                    self.submitting = false;
                  });
									
                }

             }
            else{
              self.submitting = false;

              this.$refs.notification.createNotification("Please check the form for omissions", true);

            }
        },
        updateAudio(url, index){

          if (url.includes("m.soundcloud.com")){
            url = url.substring(10, url.length);
            url = 'https://' + url;
            this.form.audio[index] = url; 
          }
            
            let morphedUrl = changeAudio(url);

            this.$refs["audio_" + index][0].src = morphedUrl;
        },
        // eslint-disable-next-line no-unused-vars
        changeVideo(url, index){

          if (url.includes("fb.watch")){
                url = url.replace( 'https://fb.watch/' , 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Ffb.watch%2F');
                this.form.videos[index] = url;  
          } else if (url.includes("facebook.com/plugins/") || url.includes("vimeo.com/")){
            this.form.videos[index] = url;  
          } else if (url.includes("youtube.com")) {
            if (url.includes("/shorts/")){
              url = url.replace('https://www.youtube.com/shorts/', 'https://www.youtube.com/watch?v=');
              this.form.videos[index] = url;
            } else {
              this.form.videos[index] = url;
            }
          } else if (url.includes("instagram.com")){
            if (url.includes("/reel/") || url.includes("/p/") || url.includes("/tv/")){
              if(url.includes("/tv/")){
                url = url.replace('tv', 'reel');
              }

              if(url.includes("/?igshid")) {
                const urls = new URL(url);
                const searchParams = urls.searchParams;
                let id = searchParams.get("igshid");
                let search = '?igshid=' + id;
                url = url.replace(search, 'embed');
                this.form.videos[index] = url;
              } else {
                this.form.videos[index] = url + 'embed';
              }
             } 
            // else {
            //   this.$refs.notification.createNotification('Invalid Video Link!', true);
            //   this.form.videos[index] = '';
            // }
           } 
          // else {
          //     this.$refs.notification.createNotification('Invalid Video Link!', true);
          //     this.form.videos[index] = '';
          // }    
          
          switch(index){
              case 0: {
                  this.$refs.video_0.src = url;
                break;
              }
              case 1: {
                  this.$refs.video_1.src = url;
                  break;
              }
              case 2: {
                  this.$refs.video_2.src = url;
                  break;
              }
          }

        },
      
    },
    data() {
		return {
      excludes: {},
      speciality_button_css: '',
      specialities: [],
      specialisms: [],
      specialismsExisting: {},
      locations: [],
      locations_list: [],
      dailyFees: [],
      specialFees: [],
      locations_listCopy: [],
      specialitiesExiting: [],
      specialitiesToDelete: [],
      specialitiesToAdd: [],
      tableIndex: -1,
      tier_id: '',
      selections:{
        tier: null
      },
			submitting: false, 
			appConfig,
      gallery: [],
      image: null,
			pristine: null,
			pristineConfig: {
				classTo: 'input-group',
				errorClass: 'has-error',
				successClass: 'has-success',
				errorTextParent: 'input-error',
				errorTextTag: 'span',
				errorTextClass: 'text-help'
			}, 
			item: null,
      show_vat_field: false,
      billing_same_as: true,
			form: {
				search_terms: null,
				search_genres: null,
				search_tiers: null,
				address: {
					address_line_1: "",
					address_line_2: "",
					town: "",
					city: "",
					post_code: "",
				},
				billing_address: {
					address_line_1: "",
					address_line_2: "",
					town: "",
					city: "",
					post_code: "",
				},
				videos: ["", "", ""],
				audio: ["", "", ""],
        iban_number: '',
        vat_number: '',
        utr_number: '',
        song_list: [],
        played_venues: []
			},
      grouped_locations: {
        country_id: null,
        country_name: '',
        region: '',
        areas: [],
        places:[],
        ids: []
      },
      showPrivateVenuesModal: false,
      privateVenueRequests: [],
      countries: [],
      holidays: [],
      active: true,
      trackSpecialityIndex: null,
      showSpecials: true
			}
		}
  }


</script>

<style>
  .outline-line {
    outline: 1px solid #E44370;
  }
</style>
