import Vue from 'vue'
import Vuex from 'vuex'
import client from '../utils/client'
import dispatchActionForAllModules from '../utils/dispatch-action-for-all-modules'
import dictionaryModule from '@/state/modules/dictionary'; // Adjust the path accordingly
import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        dictionaryArray: [],
      },
      mutations: {
        SET_DICTIONARY(state, data) {
          state.dictionaryArray = data;
        },
      },
      actions: {
        async fetchDictionary({ commit }) {
          try {
            const response = await client.get('dictionary'); 
            commit('SET_DICTIONARY', response.data);
          } catch (error) {
            console.log('Error fetching dictionary:', error);
          }
        },
      },
    modules,
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    strict: process.env.NODE_ENV !== 'production',
    dictionary: dictionaryModule // Assign the module to a namespace
})

export default store

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init')
