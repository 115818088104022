<template>
	<GigPageWrapper
		:gig-id="String($attrs.id)" 
		current-route="offers"
		title="Offers"
	>
    <BidAccept :currency-symbol="bid.currency.symbol" :message="popupErrorMessage" :bid="bid" :specialism="specialismChosen" v-if="bid && isShowAccept" v-on:acceptBid="accept(bid)" v-on:closeModal="isShowAccept = $event;popupErrorMessage = '';" />
    <BidDecline :bid="bid" v-if="bid && isShowDecline"  v-on:declineBid="decline(bid)" v-on:closeModal="isShowDecline = $event;" />

    <InlineNotification v-if="isGigCancelled" Heading="Gig cancelled">
      <template slot="body">
        <p class="mt-2 text-justify">Below is a list of artists that were offered this gig. All artists have since been notified of the gigs cancellation. Please navigate to the gig details page using the component below if you wish to see the full detailed breakdown of this cancelled gig.</p>
        <router-link :to="'/gigs/' + $attrs.id" class="flex items-center mt-1 bg-transparent w-48"><ArrowCircleLeftIcon class="mr-2" color="pink"/>Go to gig details page</router-link>
      </template>
    </InlineNotification>


    <InlineNotification v-else-if="!isGigCancelled && !isGigBooked" Heading="Book your artists">
                    <template slot="body">
										<p class="mt-2 text-justify">Below is a list of artists that are available for this gig. Simply click “thumbs up” to book the artist you want. This will send the confirmation to your chosen artist and the gig is booked.</p>
                    </template>
    </InlineNotification>

    <InlineNotification v-else-if="!isGigCancelled && isGigBooked" Heading="Gig booked">
      <template slot="body">
        <p class="mt-2 text-justify">This gig is now booked. Once the gig has passed, you will be able to sign-off this gig and receive an invoice from the artist.</p>
      </template>
    </InlineNotification>

                <a class="gp_cta disabled hidden">Add artists using my shortlist</a>

                <div v-if="this.isLoaded && !gigStarted">
                <div v-if="groupedBids.length && !isGigBooked">
                    <div class="bid-tabs flex items-center justify-between border-b border-gray-200 ">
                        <nav class="-mb-px flex items-center justify-between overflow-x-auto overflow-y-hidden">

                                <div class="grow grid grid-flow-row gap-4 items-start"  v-for="(groupedBid, index) in groupedBids" :class="groupedBidMoreInfoId == groupedBid.info.id ? 'bid_tab_active' : ''" :key="'moreinfo_' + index">
                                    <a  @click="groupedBidMoreInfoId = groupedBid.info.id; initData()" class="grid grid-flow-col justify-center items-center gap-2">
                                        <span>{{groupedBid.info.name}}</span>
                                        <span class="bg-indigo-100 text-indigo-600 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"> {{groupedBid.count}} </span>
                                    </a>
                                </div>
                        </nav>
                    </div>

                        <div class=" flex flex-col mt-4 "  v-for="(grouping, index) in groupedBids.filter(b => b.info.id == groupedBidMoreInfoId)"  :key="'moreinfogroup_' + index">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"  :class="groupedBidMoreInfoId == grouping.info.id ? 'block' : 'hidden'" v-if="!isGigBooked && bids && bids.length > 0">
                                <div
                                class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div
                                class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-200 text-left">
                                    <thead class="bg-gray-50">
                                    <tr>
                                        <th
                                        scope="col"
                                        class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            Make choice
                                        </th>
                                        <th
                                        scope="col"
                                        class="px-2 py-3 text-left marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                        Artist
                                        </th>
                                        <th
                                        scope="col"
                                        class="px-0 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >Fee </th>
                                        <th
                                        scope="col"
                                        class="px-12 py-3 text-left marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            <td title="Copy Gig to Clipboard">
                                                <svg xmlns="http://www.w3.org/2000/svg" @click="copyGigToClipboard()" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 cursor-pointer">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                                </svg>
                                            </td>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200  text-sm">
                                    <tr v-for="(bid, index) in grouping.bids" :key="index" :row="bid" :data-bid-id="bid.id">
                                        <td class="p-4" >

                                            <div v-if="bid.declined">
                                                <p>Availability declined</p>
                                            </div>
                                            <div v-else-if="bid.cancelled">
                                                <p>Offer cancelled</p>
                                            </div>
                                            <div v-else-if="bid.gig.cancelled">
                                                <p>Gig cancelled</p>
                                            </div>
                                            <div v-else-if="bid.awaiting_artist_bid">
                                                <p>Availability pending</p>
                                            </div>
                                            <div v-else-if="bid.awaiting_venue_final_acceptance" class="flex flex-col ">
                                                <div>
                                                    <span v-if="bid.artist">{{bid.artist.name}}</span><span v-show="bid.artist.deleted_at">[Artist deleted]</span> is available.<br/>To book this artist, click thumbs up.
                                                </div>
                                                <div class="flex flex-row mt-4 space-x-8">
                                                  <a @click="showAccept(bid, grouping.info.id != 0 ? grouping.info.name : '')" title="Accept artist" class="text-green-500 hover:text-green-800 mr-2"><ThumbUpIcon/></a> <a  class="hover:text-black text-red-500" v-show="false"  title="Remove artist" @click="showDecline(bid)"><ThumbDownIcon/></a>
                                                </div> 
                                            </div>
                                            <div v-else-if="bid.awaiting_artist_final_acceptance">
                                                You have accepted this artist,<br/> awaiting artist's final confirmation.
                                            </div>
                                            <div v-else-if="bid.accepted">
                                                <span class="text-green-500 flex items-center"><CheckIcon class="mr-2"/>Artist booked </span>
                                            </div>
                                            <div v-else>

                                            </div>
                                            <p v-show="!bid.awaiting_venue_final_acceptance && !bid.accepted && !bid.min_fee_met && bid.artist_min_fee && hasMinFeeField" 
                                                class="text-xs mt-4 text-red-500"
                                            >
                                                Artist minimum fee of {{item.currency.symbol}}{{bid.artist_min_fee}} not met.
                                            </p>
                                        </td>
                                        <td class="py-4 flex-col text-left mx-2  ">
                                            <router-link v-if="bid.artist" class="text-gp_pink-default-500 block" :to="'/artist-directory/' + bid.artist.id + '/' + slugify(bid.artist.name).toLowerCase()">{{bid.artist.name}} - {{bid.artist.tier.name}}</router-link>

                                            <div class="grid grid-flow-row">
                                                <!-- <a v-if="bid.artist && bid.artist.telephone" :href="'tel:' + bid.artist.telephone">Telephone: {{bid.artist.telephone}}</a> -->
                                                <span v-if="bid.distance">Distance: {{bid.distance}} miles</span>
                                                <span v-if="bid.matched_tags">Matched on: {{bid.matched_tags}}</span>
                                            </div>
                                        </td>
                                        <td class="py-4 text-left">
                                            <span>{{item.currency.symbol}}{{bid.fee}} <span v-if="bid.artist.vat_number"> + VAT</span></span>
                                                                                <a class="text-gp_pink-default-500 pl-2" v-if="!bid.signed_off_at && !isGigCancelled"
                                                                                    @click="editBidFee(bid)"
                                                                                >Amend</a>
                                        </td>
                                        <div v-if="bid.awaiting_artist_bid && !bid.declined">
                                            <td class="px-12 py-8" title="Copy Bid to Clipboard">
                                                <svg xmlns="http://www.w3.org/2000/svg" @click="copyBidToClipboard(bid.id, bid.artist.name)" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 cursor-pointer">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                                </svg>
                                            </td>
                                        </div>
                                    </tr>
                                    </tbody>
                                </table>
                                  <Pagination
                                      @changePage="(n) => paginate_currPage = n"
                                      :paginate_from="paginate_from"
                                      :paginate_to="paginate_to"
                                      :paginate_total="paginate_total"
                                      :paginate_currPage="paginate_currPage"
                                      :paginate_lastPage="paginate_lastPage"
                                  ></Pagination>
                                </div>
                            </div>
                            </div>
                        <div v-else>
                            <NoDataMsgWrapper>{{ offerMessage }}</NoDataMsgWrapper>
                        </div>
                        </div>

                    </div>
                <div v-else>
                    <div class=" flex flex-col mt-4 " v-if="!isGigBooked && bids && bids.length > 0">
                        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div
                            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div
                            class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200 text-left">
                                <thead class="bg-gray-50">
                                <tr>
                                    <th
                                    scope="col"
                                    class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Make choice
                                    </th> 
                                    <th
                                    scope="col"
                                    class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                    
                                    </th> 
                                    <th
                                    scope="col"
                                    class="px-2 py-3 text-left marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                    Artist
                                    </th> 
                                    <th
                                    scope="col"
                                    class="px-0 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >Fee </th> 
                                    <th
                                    scope="col"
                                    class="px-12 py-3 text-left marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        <td title="Copy Gig to Clipboard">
                                            <svg xmlns="http://www.w3.org/2000/svg" @click="copyGigToClipboard()" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 cursor-pointer">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                            </svg> 
                                        </td>  
                                    </th> 
                                </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200  text-sm">
                                <tr v-for="(bid, index) in bids" :key="index" :row="bid">
                                    <td class="p-4" >
                                        
                                        <div v-if="bid.declined">
                                            <p>Availability declined</p>
                                        </div> 
                                        <div v-else-if="bid.cancelled">
                                            <p>Offer cancelled</p>
                                        </div> 
                                        <div v-else-if="bid.gig.cancelled">
                                            <p>Gig cancelled</p>
                                        </div> 
                                        <div v-else-if="bid.awaiting_artist_bid">
                                            <p>Availability pending</p>
                                            <p v-show="!bid.min_fee_met && bid.artist_min_fee" class="text-xs mt-1 text-red-500">Artist minimum fee of {{item.currency.symbol}}{{bid.artist_min_fee}} not met.</p>
                                        </div> 
                                        <div v-else-if="bid.awaiting_venue_final_acceptance" class="flex flex-col ">
                                        <div>
                                            <span v-if="bid.artist">{{bid.artist.name}}</span><span v-show="bid.artist.deleted_at">[Artist deleted]</span> is available,<br/>to book this artist, click thumbs up.
                                        </div>
                                        <div class="flex flex-row mt-4 space-x-8">                                            
                                            <a @click="showAccept(bid)" title="Accept artist" class="text-green-500 hover:text-green-800 mr-2"><ThumbUpIcon/></a> <a  class="hover:text-black text-red-500" v-show="false"  title="Remove artist" @click="showDecline(bid)"><ThumbDownIcon/></a>  
                                            </div>
                                        </div>
                                        <div v-else-if="bid.awaiting_artist_final_acceptance">
                                            You have accepted this artist,<br/> awaiting artist's final confirmation.
                                        </div>
                                    <div v-else-if="bid.accepted">
                                        <span class="text-green-500 flex items-center"><CheckIcon class="mr-2"/>Artist booked </span>
                                    </div> 
                                    <div v-else> 
                                   </div>
                                </td>
                                <td class="py-2 px-4" >
                                    <router-link class="text-gp_pink-default-500" :to="'/messages/offer/' + bid.gig.id + '/' + bid.id">Message artist</router-link>  
                                </td> 
                                <td class="py-4 flex-col text-left mx-2  ">
                                    <router-link v-if="bid.artist" class="text-gp_pink-default-500 block" :to="'/artist-directory/' + bid.artist.id + '/' + slugify(bid.artist.name).toLowerCase()">{{bid.artist.name}}</router-link> 
                                    <span v-if="bid.artist.tier.name">{{bid.artist.tier.name}}</span>
                                    <br>
                                    <span v-if="bid.distance">Distance: {{bid.distance}} miles</span> 
                                    <span v-if="bid.matched_tags">{{bid.matched_tags}}</span>
                                </td> 
                                <td class="py-4 text-left">
                                    <span>{{item.currency.symbol}}{{bid.fee}}</span>
																		<a class="text-gp_pink-default-500 pl-2" v-if="!bid.signed_off_at"
																			@click="editBidFee(bid)"
																		>Amend</a>
                                </td>
                                 <div v-if="bid.awaiting_artist_bid && !bid.declined">
                                    <td class="px-12 py-8" title="Copy Bid to Clipboard">
                                        <svg xmlns="http://www.w3.org/2000/svg" @click="copyBidToClipboard(bid.id, bid.artist.name)" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                        </svg> 
                                    </td>  
                                </div>  
                            </tr>
                            </tbody>
                        </table>
                              <Pagination
                                  @changePage="(n) => paginate_currPage = n"
                                  :paginate_from="paginate_from"
                                  :paginate_to="paginate_to"
                                  :paginate_total="paginate_total"
                                  :paginate_currPage="paginate_currPage"
                                  :paginate_lastPage="paginate_lastPage"
                              ></Pagination>
                        </div>
                    </div> 
                </div>
                    </div>            
            </div>
        </div>

        <NoDataMsgWrapper v-else-if="!isGigBooked">Loading offers page. Please wait.</NoDataMsgWrapper>

            <NotificationSmall ref="notification" />

						<template v-if="selectedBid">
							<AmendBidFee 
								:is-open="showAmendBidFeeModal"
								:bid="selectedBid"
								@closed="cancelModal"
								@updated="onBidFeeUpdated"
							/>
						</template> 
	</GigPageWrapper>

				

</template>

<script>  
import NotificationSmall from '../../components/notifications/small.vue'; 
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue';
import { apiComputed, apiMethods } from '@/state/helpers'; 
import InlineNotification from '../../components/notifications/inline.vue'
import BidAccept from '../../components/modal/BidAccept.vue';
import BidDecline from '../../components/modal/BidDecline.vue';
import { ThumbUpIcon, ThumbDownIcon, CheckIcon, ArrowCircleLeftIcon } from "@vue-hero-icons/outline"   
import formatDate from '../../utils/format-date.js';
import NProgress from 'nprogress';
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import slugify from 'slugify';

import AmendBidFee from '../../components/modal/gigs/AmendBidFee.vue'
import Pagination from "@/components/elements/Pagination.vue";

export default {
      name: "GigBids",
      components:{
      GigPageWrapper,
      NotificationSmall,
      InlineNotification,
      BidAccept,
      BidDecline,
      NoDataMsgWrapper,
      ThumbUpIcon,
      ArrowCircleLeftIcon,
      ThumbDownIcon,
      CheckIcon,
      AmendBidFee,
      Pagination
}, 
    data ()
    {
       return {
         paginate_from: null,
         paginate_to: null,
         paginate_total: null,
         paginate_currPage: 1,
         paginate_lastPage: null,
          popupErrorMessage: "",
          item: null,
          isLoaded: false,
          bids:[],
          specialismChosen: '',
          groupedBidMoreInfoId: 0,
          groupedBids: [],
          bid: null,
          isShowAccept: false,
          isShowDecline: false,
          gigCopy: '',
          pristine: null,
          pristineConfig: {
              classTo: 'input-group',
              errorClass: 'has-error',
              successClass: 'has-success',
              errorTextParent: 'input-error',
              errorTextTag: 'span',
              errorTextClass: 'text-help'
          },
          showAmendBidFeeModal: false,
          selectedBid:null,
       }
    },
    watch: {
      paginate_currPage() {
        this.filter();
      },
    },
    created() {
        this.initData();
    },
  computed: {
    hasMinFeeField() {
        return new Date(this.item.created_at) > new Date('2024-01-04')
    },
    isGigBooked() {
      return this.$store.getters['gig/isCurrentGigBooked']
    },
    isGigCancelled() {
      return this.$store.getters['gig/isCurrentGigCancelled']
    },
    form: function() {

        let deepCopy = JSON.parse(JSON.stringify(this.item));
        return deepCopy;
    },
    gigStarted()
    {
        let gigStartDate = new Date(this.item.start);
        gigStartDate.setDate(gigStartDate.getDate());

        return formatDate(new Date(), 'yyyy-MM-dd HH:mm') > formatDate(gigStartDate, 'yyyy-MM-dd HH:mm');
    },
    ...apiComputed, 
		offerMessage(){
			if(!this.item){
				return ''
			}
			if(!this.item.published){
				return `Use the "Offer gig to artists" button to publish your gig.  GigPig will then notify you shortly of any artists that have said they are available.`
			}else if(this.item.bids && !this.item.bids.length){
				return `GigPig is out looking for artists. Once we've made some matches, you'll see them here, and can offer them the gig if they're available.`
			}else if(this.item.bookings && this.item.bookings.length < this.item.max_bookings && this.item.max_bookings > 1){
				return `This gig requires multiple bookings (${this.item.max_bookings} in total). GigPig is out looking for other artists. Once we've made some matches, you'll see them here, and can offer them the gig if they're available.`
			}else{
				return ``
			}
		}
    }, 
    methods:{
        slugify,
        initData(){
            NProgress.start();

            this.getGigBidsWithOffers(
                {
                  id: this.$attrs.id,
                  useTrashedArtists: false,
                  page: this.paginate_currPage,
                  artistTagId: this.groupedBidMoreInfoId ? this.groupedBidMoreInfoId : null,
                  queryParams: [
                      'bids',
                      'bids.artist',
                      'bids.artist.specialities',
                      'bids.artist.specialities.moreInfo',
                      'venue.address',
                      'bookings'
                  ]
                }).then((resp) => {
                    this.item = resp;

                    if (this.item.artist_tag_id !== undefined && this.item.artist_tag_id !== null) {
                      this.groupedBidMoreInfoId = parseInt(this.item.artist_tag_id)
                    }

              this.paginate_lastPage = this.item.bids.last_page
              this.paginate_currPage = this.item.bids.current_page

            }).finally(() => {
                    this.loadBids();
                    this.$store.dispatch('gig/storeGig', this.item); 
                    this.isLoaded = true;
                    NProgress.done();
            })
        },
        loadBids(){
          this.paginate_from = this.item.bids.from
          this.paginate_to = this.item.bids.to
          this.paginate_total = this.item.bids.total
          this.item.bids = this.item.bids.data

          this.groupedBids = [{
            info: {
              id: 0,
              name: 'All'
            },
            bids: [],
            count: 0
          }]

          this.groupedBids[0].count = this.item.bid_count

          const specialities = this.item.specialities.filter(spec => {
            return spec.more_info.length
          })

          let alreadyCounted = []

          specialities.forEach(spec => {
            if (this.groupedBids.filter(gb => {
              return gb.info.id === spec.more_info[0].id
            }).length === 0) {
              this.groupedBids.push(
                  {
                    info: {
                      id: spec.more_info[0].id,
                      name: spec.more_info[0].name
                    },
                    bids: this.item.bids,
                    count: 0
                  }
              )
            }

            if (!alreadyCounted.filter(ac => {
              return ac.artist_id === spec.artist_id && ac.more_info_id === spec.more_info[0].id
            }).length) {
              this.groupedBids.find(t => {
                return t.info.id === spec.more_info[0].id
              }).count++

              alreadyCounted.push({artist_id: spec.artist_id, more_info_id: spec.more_info[0].id})
            }

          })

            this.groupedBids[0].bids = this.item.bids.filter(f => f.artist != null);

            if (this.groupedBids.length){
                this.groupedBids.sort((a, b) => b.bids.length - a.bids.length)
            }

            this.bids = this.item.bids.filter(f => f.artist != null);
        },
      filter(){

        NProgress.start();

        this.getGigBidsWithOffers(
            {
              id: this.$attrs.id,
              useTrashedArtists: false,
              page: this.paginate_currPage,
              artistTagId: this.groupedBidMoreInfoId ? this.groupedBidMoreInfoId : null,
              queryParams: [
                'bids',
                'bids.artist',
                'bids.artist.specialities',
                'bids.artist.specialities.moreInfo',
                'venue.address',
                'bookings'
              ]
            }).then((resp) => {
          this.item = resp;
          this.paginate_lastPage = this.item.bids.last_page
          this.paginate_currPage = this.item.bids.current_page
        }).finally(() => {
              this.loadBids();
              this.isLoaded = true;
              NProgress.done();
        });
      },
        formatDate,
        ...apiMethods,
        showAccept($bid, $specialismChosen){
            this.isShowAccept = true;
            this.bid = $bid;
            this.specialismChosen = $specialismChosen;
        },
        showDecline($bid){ 
            this.isShowDecline = true;
            this.bid = $bid;
        },
        accept($bid){ 
            this.save($bid, true);
        },
        decline($bid){ 
            this.save($bid, false);
        },
        save($bid, $isAccept = false){
                
            let payload = { 
                id: $bid.id, 
                accepted: $isAccept,
                declined: !$isAccept   

            };

            if (this.specialismChosen) {
                payload.speciality_chosen = this.specialismChosen;
            }

            let self = this;
            
            if ($isAccept){

                this.acceptAvailability(payload)  
                    .then(() => {
                        this.proceed($isAccept);
                    })
                    .catch((err) => {
                        if (err.response) {
                           self.popupErrorMessage=err.response.data.message;
                           self.hasError = true;
                        }
                    });
            }else{

                this.declineAvailability(payload)
                    .finally(this.proceed($isAccept));
            }

        },
        proceed($isAccept){


            this.getGigWithOffers({id: this.$attrs.id, useTrashedArtists: false}).then((resp) => {
                    this.item = resp;
                    this.bids = resp.bids;
                }).finally(() => {                    
                    this.$notify("Availability " + ($isAccept ? 'accepted' : 'declined'));
                    this.isShowAccept = false;
                    this.isShowDecline = false;
                    setTimeout(() => {                       
                        this.$router.go(); 
                    }, 1000);
                });
        },
        editBidFee($bid){
            this.selectedBid = $bid;
            this.showAmendBidFeeModal = true;
        },
        cancelModal(){
            this.showAmendBidFeeModal = false;
        },
        onBidFeeUpdated($bid){
            this.selectedBid = $bid;
            this.cancelModal();
            this.initData();
        },
        copyBidToClipboard($id, $name){
            let copyText = 'https://app.gigpig.uk/artist/offers/' + $id;
            navigator.clipboard.writeText(copyText).then(() => {
                this.$refs.notification.createNotification('Artist bid link for ' + $name +' copied to clipboard');
            });
        },
        copyGigToClipboard(){

            let copyText = 'Event: \n' + this.item.name + '\n\n' +
                           'Date: \n' + formatDate(this.item.start, 'dd/MM/yy HH:mm').replaceAll(',', '') + ' until ' + formatDate(this.item.end, 'dd/MM/yy HH:mm').replaceAll(',', '') + '\n\n' +
                           'Fee: \n£' + this.item.gig_bids[0].fee + '\n\n' +
                           'Address: \n' + this.item.venue.address.address_line_1 + '\n' +
                                           this.item.venue.address.address_line_1 + '\n' +
                                           this.item.venue.address.town + '\n' +
                                           this.item.venue.address.county + '\n' +
                                           this.item.venue.address.post_code + '\n\n' +
                           '------------------------------------------------\n\n' + 
                           'Instruction and Equipment at Venue: \n' + this.item.equipment_venue + '\n\n' +
                           'Equipment the artist needs to bring: \n' + this.item.equipment_artist + '\n\n' +
                           'Repertoire: \n' + this.item.repertoire + '\n\n' +
                           'Dress code: \n' + this.item.dress_code + '\n\n' +
                           'Parking: \n' + this.item.parking_details + '\n\n' +
                           'Load in times: \n' + this.item.load_in_time + '\n\n' +
                           'Soundcheck times: \n' + this.item.sound_check_time;

            navigator.clipboard.writeText(copyText).then(() => {
                this.$refs.notification.createNotification('Event info copied to clipboard');
            });
        }
    }
}
</script>


<style lang="postcss" scoped>
	
    .bid-tabs a {
        @apply selection:border-transparent text-gray-500  hover:text-gray-700  hover:border-gray-200 whitespace-nowrap flex py-4 px-1 font-medium text-sm;
	} 

    .bid_tab_active > a {
        @apply border-b-2 border-indigo-500 text-indigo-500;
    }
	
</style>