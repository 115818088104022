<template>
	<PageDetails>
		<div class="flex-1 flex flex-col min-h-full">

			<!-- Navigation, DisplayDate/Refresh and Filter slot-->
			<div class="flex flex-col lg:flex-row justify-between lg:items-center mb-4 gap-4 lg:gap-8">

				<!-- Responsive div to display the Navigation and DisplayDate/Refresh -->
				<div class="flex flex-col-reverse lg:flex-row gap-2 lg:gap-4">
					<!-- Calendar Navigation buttons -->
					<div class="flex items-center gap-4">
						<select 
							@change="updateCalendar" 
							v-model="view" 
							class="block py-2 pl-3 pr-10 text-gray-600 text-base font-medium placeholder-gray-400 transition duration-100 ease-in-out hover:text-gray-700 border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:border-gray-300 rounded">
							<option 
								v-for="viewOption in viewOptions" 
								:key="viewOption.value" 
								:selected="viewOption.value === view"
								:value="viewOption.value"
							>
								{{ viewOption.text }}
							</option>
						</select>

						<GPButton @click.native="showToday" color="outline-gray">
							Today
						</GPButton>

						<GPButton @click.native="prevBlock" color="outline-gray">
							<ChevronLeftIcon />
						</GPButton>

						<GPButton @click.native="nextBlock" color="outline-gray">
							<ChevronRightIcon />
						</GPButton>
					</div>

					<!-- DisplayDate/Refresh, sits on top in mobile view -->
					<div class="flex items-center gap-4">
						<div class="flex gap-4" >
							<div class="text-center font-bold col-span-4 lg:col-span-1">
								{{ getCalendarDate }}
							</div>
							<button @click="onRefresh" title="Refresh calendar" >
								<RefreshIcon/>
							</button>
						</div>
					</div>
			
				</div>

				<!-- Filters slot - takes 100% of width when mobile -->
				<div class="flex-1 flex w-full justify-end items-center gap-4">
					<slot name="filters"></slot>
				</div>
				
			</div>

			<!-- Status keys, displays the availableStatusKeys prop and wraps -->
			<div class="flex-1 flex w-full justify-between">
				<div class="flex items-center flex-wrap mb-4 gap-2">
					<gig-status-checkbox
						ref="statuscheckbox"
						:venueFilter="selectedVenue"
						:statuses="availableStatusKeys" 
						v-on:statusFiltersUpdated="$emit('statusFiltered', $event)"
					/>
				</div>
				<div class="flex items-center justify-end">
					<slot name="additionalFilters"></slot>
				</div>
			</div>

			<!-- Only show if venue -->
			<div v-show="isVenue && isGenre" class="mb-6">
				<strong>Custom Tags</strong>
				<div class="flex items-center space-x-3 mt-2">
					<div class="w-80">
						<TRichSelect 
							multiple
							v-model="setTagFilters"
							ref="iconselect"  
							class="col-span-12 lg:col-span-5 cursor-pointer"
							:close-on-select="false" valueAttribute="id"  textAttribute="name"
							:options="venue_custom_tags"
							placeholder="Filter by custom tag" 
						>  
							<template slot="option" slot-scope="{ index, isHighlighted, isSelected, className, option }">                                 
								<div class="flex gap-4 py-2">
									<component :is="icons[option.raw.icon]" class="ml-3"/>  {{ option.raw.name }} 
								</div>
							</template>

                        </TRichSelect> 
					</div>
					<div v-show="hasTagAccess">
						<button @click="openCreateTagModal()" class="flex justify-center items-center bg-gp_pink-default-500 hover:bg-gp_pink-default-400 text-white text-sm font-bold py-1 px-4 rounded-3xl">
							<component :is="icons['PlusIcon']" class="w-5 h-5 mr-1"/>
							<span class="hidden md:block">New Tag</span>
						</button>
					</div>
				</div>
			</div>

		<!-- Calendar -->
			<div ref="calendarContainer" class="flex-1 flex flex-col w-full z-0">
				<Calendar 
					ref="tuiCalendar" 
					:style="getCalendarStyle"
					:useCreationPopup="false"
					:useDetailPopup="false"
					:usageStatistics="false"
					:schedules="schedules"        
					:view="view"
					:taskView="false"
					:scheduleView="scheduleView"
					:calendars="getCalendarList"
					@beforeCreateSchedule="beforeCreateSchedule"
					@afterRenderSchedule="afterRenderSchedule"
					@clickSchedule="clickSchedule"
					:isReadOnly="isReadOnly" 
				/>
			</div>
		</div>
		<CreateCustomGigTag ref="createGigTag" v-on:addedTag="newCreatedTag($event)"/> 
	</PageDetails>
</template>

<script>
import PageDetails from '../ui/wrappers/PageDetails.vue'
import GPButton from '../ui/buttons/Button.vue'
import GigStatusCheckbox from '../iconsets/GigStatusCheckbox.vue';
import { 
	ChevronLeftIcon, 
	ChevronRightIcon, 
	RefreshIcon 
} from "@vue-hero-icons/outline"
import { TRichSelect } from 'vue-tailwind/dist/components';
import { Calendar } from '@toast-ui/vue-calendar';
import 'tui-calendar/dist/tui-calendar.css'; 
import formatDate from '../../utils/format-date';

import  * as icons from "@vue-hero-icons/outline"
import CreateCustomGigTag from '../../components/modal/venues/CreateCustomGigTag.vue';
import VenueCalendar from '../../views/Calendar/VenueCalendar.vue';
import client from '@/utils/client.js'
import {mapState} from "vuex";
import nprogress from 'nprogress'; 

const { DateTime, Interval } = require("luxon");

const CURRENT_DATE = "CALENDAR_CURRENT_DATE";

export default {
	components:{
		PageDetails,
		GPButton,
		GigStatusCheckbox,
		ChevronLeftIcon,
		ChevronRightIcon,
		RefreshIcon,
		TRichSelect,
		Calendar,
		CreateCustomGigTag,
		VenueCalendar
	},
	props:{
		isReadOnly:{
			type: Boolean,
			required: false
		},
		calendarType:{
			type:String,
			required: true
		},
		viewOptions:{
			type:Array,
			required: true
		},
		availableStatusKeys:{
			type:Array,
			required: true
		},
		schedules:{
			type:Array,
			required: true
		},
		scheduleView:{
			type:Array,
			required: false,
			default:() => ['time']
		},
		selectedVenue:{
			required: true
		}
	},
	emits:["update", "selected", "refresh", "create"],
	data(){
		return {
			view: 'month',
			tooltip: false,
			calendar: null,
			currentDate: null,
			startDateRange: null,
			endDateRange: null,
			containerHeight: 700,
			currentMonth: null,
			statusFilters: [],
			icons: icons,
			setTagFilters: null,
			venue_custom_tags: [], 
		}
	},
	computed:{
		...mapState({
			userRoles: (state) => state.user.rolesPermissionsSlugs,
		}),
        hasTagAccess()
        {
            if (this.userRoles && this.userRoles.length > 0) {
                return this.userRoles[0] == 'full-access';
            } else {
                return null; 
            }
        },
		userData(){
			return this.$store.getters['user/userData']
		},
		isGenre() {
			return this.userData.email.includes('genremusic')
		},
		getStatusKey(){
			return (status, index) => `${index}-${status.type}`
		},
		getCalendarStyle(){
			return `height: ${this.containerHeight}px`
		},
		isVenue() {
			return localStorage.initialRoute == 'venue'
		},
		getCalendarList(){
			return [{
				id: 0,
				name: this.calendarType,
				color: '#ffffff',
				bgColor: '#9e5fff',
				dragBgColor: '#ff4040',
				borderColor: '#fff'
			}]
		},
		getCalendarDate(){
			return this.currentDate ? this.formatDate(this.currentDate, 'MMMM yyyy') : "";
		}
	},
	watch: { 
		setTagFilters(val)
		{
			this.$emit('tagFiltered', val);
		},
	},
	methods:{ 
		emitCalendar(){

			nprogress.start();
			const start = DateTime.fromJSDate(this.startDateRange);
			const end = DateTime.fromJSDate(this.endDateRange);
			
			const interval = Interval.fromDateTimes(start, end);

			const daysDifference = interval.toDuration('days');

			if (daysDifference.values.days < 10)
			{
				let moveStart = start.minus({ months: 1});
				this.startDateRange = moveStart.toJSDate();		
				let moveEnd = end.plus({ months: 1});		
				this.endDateRange = moveEnd.toJSDate();
			}			
 

			this.$emit('update', {
				view:this.view,
				currentDate:this.currentDate,
				startDateRange:this.startDateRange,
				endDateRange:this.endDateRange,
			});

			nprogress.done(); 
		},
		formatDate,
		newCreatedTag(event) {
			this.venue_custom_tags.push(event)
		},
		openCreateTagModal() {
			this.$refs.createGigTag.modalOpen = true;
		},
		showToday(){
			this.$refs.tuiCalendar.invoke('today');
			this.updateCalendar();
		},
		nextBlock(){
			this.$refs.tuiCalendar.invoke('next');
			this.updateCalendar();
		},
		prevBlock(){
			this.$refs.tuiCalendar.invoke('prev');
			this.updateCalendar();
		},
		allVenueFilterSelected() {
			this.$refs.statuscheckbox.allSelected();
		},
		selectVenueFilterSelected() {
			this.$refs.statuscheckbox.venueSelected();
		},
		updateCalendar(){
			console.log("updateCalnedar");
			this.updateDates();		
			this.emitCalendar();
 
		},
		updateDates(){
			this.startDateRange = this.$refs.tuiCalendar.invoke('getDateRangeStart')._date;
			this.endDateRange = this.$refs.tuiCalendar.invoke('getDateRangeEnd')._date;

			let $middleDate = new Date(this.endDateRange - (this.endDateRange - this.startDateRange) / 2);
 
			let $month = this.formatDate($middleDate, 'MMMM');

			localStorage.setItem(CURRENT_DATE, this.$refs.tuiCalendar.invoke('getDate')._date);
			this.currentDate = $middleDate;
			this.currentMonth = $month;
 

		},
		clickSchedule(event){
			const schedule = event.schedule;
			this.$emit('selected', schedule);
		}, 
		beforeCreateSchedule(event){
			this.$emit('create', event); 
		},
		afterRenderSchedule(){ 
		},
		getCalendarContainerHeight() {
			const currHeight = this.$refs.calendarContainer.clientHeight;
			if(!this.containerHeight || this.containerHeight < currHeight){
				this.containerHeight = currHeight;
				this.$refs.tuiCalendar.invoke('render');
			}
		},
		onRefresh(){
			this.$emit('refresh');
		},
		renderUpdate(){
			console.log("rendered");
		}
	},
	mounted(){
		// is mobile
		let isMobile = window.matchMedia("(max-width: 700px)");
		if (isMobile.matches){ 
			this.view = "day";
		}

		if (localStorage.initialRoute == 'venue') {
			client.get('custom-tags').then(response => (this.venue_custom_tags = response.data.data))
		}

		// init calendar container height
		this.getCalendarContainerHeight();
		window.addEventListener("resize", this.getCalendarContainerHeight);
		// init dates
		this.updateDates();
		if(this.calendarType.toLowerCase() === 'gigs'){
			// trigger an initial 'update event'
			this.updateCalendar()
		}
		let storedDate = localStorage.getItem(CURRENT_DATE);
		if (storedDate && this.calendarType.toLowerCase() === 'gigs'){
			this.$refs.tuiCalendar.invoke('setDate', new Date(storedDate));
			this.currentDate = new Date(storedDate); 
		}		
	},
	destroyed() {
		window.removeEventListener("resize", this.getCalendarContainerHeight);
	},
}
</script>


<style>
	.tui-full-calendar-weekday-schedule[style*="16, 185, 129"] .tui-full-calendar-weekday-schedule-title,
	.tui-full-calendar-weekday-schedule[style*="180, 83, 9"] .tui-full-calendar-weekday-schedule-title{
		color: white !important;
	}
</style>
