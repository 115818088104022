<template>
		<GigPageWrapper
			:gig-id="String($attrs.id)" 
			current-route="bookings"
			title="Bookings"
		>
						
				<InlineNotification Heading="Your confirmed bookings" class="p-0 m-0 mt-4">
					<template slot="body">
						<p>You can view your confirmed bookings below. From here you can interact with your booked artist, including sending messages for any additional gig info.</p>
					</template> 
				</InlineNotification> 


						<a class="gp_cta disabled hidden">Add artists using my shortlist</a> 
						<div v-if="this.isLoaded">
							<div class=" flex flex-col mt-4 " v-if="item && item.bookings && item.bookings.length > 0">
									<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div
											class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
									>
											<div
											class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
											>
											<table class="min-w-full divide-y divide-gray-200 text-left">
													<thead class="bg-gray-50">
													<tr>
															<th
															scope="col"
															class="px-6 py-3 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
															>
															Artist
															</th> 
															<th
															scope="col"
															class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
															>
															Fee
															</th>
															<th
															scope="col"
															class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
															>
															Contact
															</th>
															<th scope="col" class="relative px-6 py-3">
																<span class="sr-only">Action</span>
															</th>
															<th scope="col" class="relative px-6 py-3">
																<span class="sr-only">Delete</span>
															</th>
													</tr>
													</thead>
													<tbody class="divide-y text-sm">
														<tr v-for="(booking, index) in item.bookings" :key="index" :row="booking">
															<!-- Artists name -->
															<td v-if="!booking.artist.deleted_at" class="p-6">
																<router-link class="text-gp_pink-default-500 block" :to="'/artist-directory/' + booking.artist.id + '/' + slugify(booking.artist.name).toLowerCase()">{{booking.artist.name}}</router-link>
															</td>
															<td v-else class="p-6">
																{{booking.artist.name}} <span v-show="booking.artist.deleted_at" class="text-xs">[Artist deleted]</span>
															</td>
															<!-- Fee -->
															<td class="p-6">
																<span>{{booking.gig.currency.symbol}}{{booking.fee}} <span v-if="booking.artist.vat_number">+ VAT</span></span>
																<a class="text-gp_pink-default-500 pl-2" v-if="!booking.signed_off_at"
																	@click="editBidFee(booking)"
																>Amend</a>
															</td>
															<!-- Contact phone number -->
															<td class="p-6">
																<span>
																	<a :href="'tel:' + booking.artist.telephone" class="text-indigo-500">
																		{{booking.artist.telephone}}
																	</a>
																</span>
															</td>
															<!-- Message -->
															<!-- <td class="text-right p-6">
																<router-link
																		v-if="booking.artist.is_active"
																		class="text-gp_pink-default-500" :to="'/messages/offer/' + booking.id"
																>Message artist
																</router-link>
																<span v-else class="text-gray-400">Message artist</span>

															</td> -->
															<!-- Delete -->
															<td class="text-right p-6">
																<template v-if="!booking.cancelled && !item.cancelled">
																		<a class="" @click="onDeleteBooking(booking)">Cancel Booking</a>
																</template>
																<template v-else>
																	<span class="">Booking cancelled </span>
																</template>
															</td>

														</tr>
													</tbody>
											</table>
											</div>
									</div>
									</div>
							</div>
							<div class="mt-4" v-else>
								<NoDataMsgWrapper>There have been no bookings for this event.</NoDataMsgWrapper>
							</div>
						</div>
						<NoDataMsgWrapper v-else>Loading gig bookings page. Please wait.</NoDataMsgWrapper>

						
				<!-- </div>
		</div> -->

		<NotificationSmall ref="notification" />
		<!-- Modals -->
		<template v-if="item">
			<template v-if="selectedBooking">
				<CancelBooking	
					identity="cancelBookingModal"
					:is-open="cancelBookingModalIsOpen" 
					:gig-id="item.id"
					:gig-name="item.name"
					:gig-start="item.start"
					:booking="selectedBooking"
					:gig="item"
					@closed="onModalClosed"
					@deleted="onBookingDeleted"
				></CancelBooking>
			</template>

			<template v-if="selectedBid">
				<AmendBidFee 
					:is-open="showAmendBidFeeModal"
					:bid="selectedBid"
					@closed="cancelModal"
					@updated="onBidFeeUpdated"
				/>
			</template>

		</template>

	</GigPageWrapper>

</template>

<script>  
import NotificationSmall from '../../components/notifications/small.vue'; 
// import GigTabs from '../../components/gigs/Tabs.vue';  
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue'; 
import { apiComputed, apiMethods } from '@/state/helpers'; 
import InlineNotification from '../../components/notifications/inline.vue'
import formatDate from '../../utils/format-date.js';
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import NProgress from 'nprogress';
import CancelBooking from '../../components/modal/gigs/CancelBooking.vue'; 
import AmendBidFee from '@/components/modal/gigs/AmendBidFee.vue'
import slugify from 'slugify';

export default {
	name: "GigBids",
	components:{
    GigPageWrapper, 
    NotificationSmall,
    InlineNotification,
    CancelBooking,
	NoDataMsgWrapper,
		AmendBidFee
	}, 
	data (){
		return { 
			item: null,
			isLoaded: false,
			booking: null,
			selectedBooking:null,
			cancelBookingModalIsOpen: false,
			showAmendBidFeeModal: false,
			selectedBid:null,
		}
	}, 
    
	computed: {
		form: function() {
			let deepCopy = JSON.parse(JSON.stringify(this.item));            
			return deepCopy;  
		},
		...apiComputed, 
	}, 

		
	methods:{
		slugify,
		// open modals
		onDeleteBooking($bid){
			this.selectedBooking = null;
			setTimeout(() => {
				this.selectedBooking = $bid;
				this.cancelBookingModalIsOpen = true;
			}, 50)
			
		},
		// close modals 
		onModalClosed(){
			this.cancelBookingModalIsOpen = false;
		},
		// CRUD methods
		onBookingDeleted(deletedPayload){
			this.onModalClosed();
			this.selectedBooking = null;
			if(deletedPayload.cancel_entirely){
				// No gig, go back to pending gigs
				this.$router.push('/pending-gigs')
			}else{
				this.loadPageData();
			}
		},
		formatDate,
		...apiMethods,
		loadPageData(){
			NProgress.start();
			this.getGigWithOffers({id: this.$attrs.id, useTrashedArtists: true})
			.then((resp) => {
				this.item = resp;
				this.$store.dispatch('gig/storeGig', this.item);
			}).finally(() => {
				NProgress.done();
				this.isLoaded = true;
			});
		},
		editBidFee($bid){
			this.selectedBid = $bid;
			this.showAmendBidFeeModal = true;
		},
		cancelModal(){
			this.showAmendBidFeeModal = false;
		},
		onBidFeeUpdated($bid){
			this.selectedBid = $bid;
			this.cancelModal();
			this.loadPageData();
		},
	},
	created() {   
		this.loadPageData();
	},
}
</script>