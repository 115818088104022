<template>
  <div>

    <Header Heading="Artist Directory" hidden />

    <InlineNotification Heading="Artist Directory" class="ml-4 mb-4" DismissId="ArtistDirectory">
        <template slot="body">
          <p>Every artist that is signed up to GigPig is stored right here.</p>

          <p>Use the filter below to help you find the specific style of artist that you want for your gig. Or if you know the name of the artist, search by name and find them that way.</p>

          <p>
            If you find someone you really like, click on the “star” and add them to your favourites.
          </p>
        </template>
    </InlineNotification>
    <div class="max-w-7xl p-1 lg:m-6 ">

      <div class="max-w-4xl  mb-6 gap-4 px-2 lg:px-0  grid grid-flow-row">

        <div class="grid grid-flow-row lg:grid-flow-col items-center gap-4   w-full">
            <span>Pick a type of artist you'd like to search for</span>
             <t-rich-select

            :close-on-select="true" v-model="selections.tier"
            :options="tiers" text-attribute="name" value-attribute="id"
            placeholder="Type" class="w-full"  ref="tiers" @change="changeTiers"
        ></t-rich-select>
      </div>


      <div class="grid grid-flow-row lg:grid-flow-col items-center gap-4   w-full black-placeholder"  :class="set_disabled(filters.show_more_infos)">
          <span>Anything in particular?</span>
        <t-rich-select
            multiple
            :close-on-select="false" v-model="selections.more_infos"
            :options="filters.more_infos" text-attribute="name" value-attribute="id"
            placeholder="No particular preference" class="w-full "  ref="specialities"
        ></t-rich-select>
      </div>

      <div class="grid grid-flow-row lg:grid-flow-col items-center gap-4   w-full" :class="set_disabled(filters.show_covers)">
          <span>Covers, originals, or both?</span>

        <t-select
            :close-on-select="false" ref="cover_originals" v-model="selections.covers"
            :options="filters.cover_originals" text-attribute="name" value-attribute="id"
            placeholder="Cover & Originals"  class="w-full "
        ></t-select>

      </div>

      <div class="grid grid-flow-row lg:grid-flow-col items-center gap-4   w-full" :class="set_disabled(filters.show_vocals)">
          <span>Do you need vocals? If so, what kind?</span>

        <t-select
            :close-on-select="false" ref="vocals"
            :options="filters.vocals" text-attribute="name" value-attribute="id"
            placeholder="No preference"  class="w-full"
        ></t-select>
      </div>

      <div class="grid grid-flow-row lg:grid-flow-col items-center gap-4 black-placeholder  w-full" :class="set_disabled(filters.show_genres)">
        <span>Pick as few or as many genres as you'd like</span>
             <t-rich-select
            multiple
            :close-on-select="false" ref="genres" v-model="selections.genres"
            :options="filters.genres" text-attribute="name" value-attribute="id"
            placeholder="Any genre"  class="w-full"
        ></t-rich-select>

      </div>

        <div
            class="grid grid-flow-row grid-flow-col items-center gap-4 black-placeholder  w-64 h-[42px]"
        >
            <label for="withMediaOnly">Only Show Artists With Media</label>
            <t-checkbox v-model="selections.has_media" id="withMediaOnly"/>
        </div>

        <div v-show="!user || (user && !user.is_opus)">
          <div class="grid grid-flow-row lg:grid-flow-col items-center gap-4   w-full">
            <span>Find artists by region</span>
            <t-rich-select
              v-model="setRegion"
              ref="region" class="w-full" placeholder="Filter by region"
              text-attribute="name" value-attribute="id"
              :close-on-select="true"
              :options="locations"  
            />
          </div>
          <div v-show="setRegion && areas.length > 0" class="grid grid-flow-row lg:grid-flow-col items-center gap-4   w-full">
            <span>Find artists by area</span>
            <t-rich-select
              v-model="setArea"
              ref="area" class="w-full" placeholder="Filter by area"
              text-attribute="name" value-attribute="id"
              :close-on-select="true"
              :options="areas"  
            />
          </div>
          <div v-show="setArea && places.length > 0" class="grid grid-flow-row lg:grid-flow-col items-center gap-4   w-full">
            <span>Find artists by place</span>
            <t-rich-select
              v-model="selections.location"
              ref="place" class="w-full" placeholder="Filter by place"
              text-attribute="name" value-attribute="id"
              :close-on-select="true"
              :options="places"  
            />
          </div>
       </div>


        <t-input v-on:keyup.enter="filter()" name="search" ref="search_name" placeholder="Search by name" v-model="selections.search_phrase" />


        <t-input v-show="is_advanced_search" v-on:keyup.enter="filter()" name="referral" ref="referral_code" placeholder="Search by referral" v-model="selections.search_referral" />

        <NoDataMsgWrapper v-show="has_filters && !isFavesLoaded">
            Loading directory. Please wait...
        </NoDataMsgWrapper>
        <button v-if="has_filters && isFavesLoaded" class="gp_cta w-full" @click="filter()" :class="has_filters ? '' : 'disabled'" :title="has_filters ? '' : 'Please select a tier or search by name'">
            Search for artists
        </button>
        <NoDataMsgWrapper v-else-if="!has_filters">
          Select a type of artist, or search by name to begin.
        </NoDataMsgWrapper>
      </div>

      <div class="flex flex-col ">
        <div class="-my-2 overflow-x-visible sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle lg:align-baseline inline-block min-w-full sm:px-6 lg:px-8">
            <div class="max-w-4xl align-baseline shadow border-b sm:rounded-lg" v-if="results.length">
              <div class="w-auto space-x-12 bg-[#0D1C43] h-10 border rounded-t-lg flex items-center uppercase text-sm font-light text-gray-200"/>
              <div v-for="(artist, index) in results" :key="index" class="w-full">
                <div class="grid grid-cols-2 lg:sm:grid-cols-4 gap-4 p-5 border">
                  <router-link :to="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()">
                    <img :src="artist.image ? (artist.conversions && artist.conversions.thumb ? artist.conversions.thumb : artist.image.url) : require('@/assets/images/placeholder.svg')" :alt="artist.name" class="w-40 h-40 object-cover object-center rounded-xl border-2" />
                  </router-link >
                  <div>
                      <router-link class="cursor-pointer" :to="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()">
                        <strong>{{ artist.name }}</strong><br/>
                        <span class="text-xs font-medium mr-2 px-2.5 py-0.5 rounded-xl bg-[#0D1C43] text-white">{{artist.tier.name}}</span>
                      </router-link>
                      <div class="mt-7 space-y-1 items-center justify-center text-center">
                        <div class="flex space-x-3 items-center">
                          <a v-if="favedArtists.includes(artist.id) == false" class="cursor-pointer text-gp_pink-default-500" @click="$refs.faveModal.toggleModal();;addArtist = artist;">
                            <StarIcon fill="none" stroke="currentColor" title="Add to favourites list" class="h-6 w-6"/>
                          </a>
                          <a v-if="favedArtists.includes(artist.id) == true" class="cursor-pointer text-gp_pink-default-500" @click="$refs.faveModal.toggleModal();;addArtist = artist; privateVenues = artist.private_venues; privateVenueIds = setPrivateArtistIds(artist.private_venues)">
                            <StarIcon title="Already in favourites list" class="h-6 w-6"/>
                          </a>
                          <p @click="$refs.faveModal.toggleModal();;addArtist = artist;" class="text-sm font-normal cursor-pointer">Add to favourites</p>
                        </div>
                        <div class="flex space-x-4 items-center">
                          <router-link :to="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()">
                            <EyeIcon fill="none" stroke="currentColor" class="cursor-pointer text-gp_pink-default-500 h-6 w-5"/>
                          </router-link>
                          <router-link  :to="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()" class="text-sm font-normal">View Profile</router-link>
                        </div>
                      </div>
                  </div>

                  <div>
                    <router-link :to="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()" class="text-sm font-normal">
                      <div v-show="artist.more_info.length !== 0" class="mb-6">
                        <strong class="mb-2 text-sm font-medium">Specialisms:</strong>
                        <div v-for="(info, index) in artist.more_info" :key="index">
                          <p class="text-sm">{{ info.name }}</p>
                        </div>
                      </div>
                      <div v-show="artist.covers.length !== 0">
                        <strong class="mt-6 mb-2 text-sm font-medium">Covers/Originals:</strong>
                        <div v-for="(cover, index) in artist.covers" :key="index">
                          <p class="text-sm">{{ cover.name }}</p>
                        </div>
                      </div>
                    </router-link>
                  </div>

                  <div>
                    <div v-show="artist.genres.length !== 0" class="mb-6">
                      <strong class="mb-2 text-sm font-medium">Genres:</strong>
                      <td class="py-2 text-sm text-gray-500 lg:table-cell">
                          <span v-show="artist.genres.length > 0" class="text-xs font-medium mr-2 px-2.5 py-0.5 rounded-xl bg-gp_pink-default-400 text-white">{{ artistGenreFirst(artist.genres[0]) }}</span>

                          <span class="relative inline-flex flex-col items-center group" v-if="artist.genres.length > 1">
                            <span
                              class="text-sm font-medium underline underline-offset-2 cursor-pointer"
                            >+{{ artist.genres.length - 1 }} more</span>
                            <div class="absolute z-10 bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
                              <span class="p-4 bg-black rounded-sm shadow-lg w-48">
                                <ul class="list-none">
                                  <li v-for="genre in artist.genres" :key="genre.id" class="text-white ">{{ genre.name }}</li>
                                </ul>
                              </span>
                              <div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
                            </div>
                          </span>

                      </td>
                    </div>
                    <router-link :to="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()" class="text-sm font-normal">
                      <div v-show="artist.vocals.length !== 0">
                        <strong class="mb-2 text-sm font-medium">Vocals:</strong>
                        <div v-for="(vocal, index) in artist.vocals" :key="index">
                          <p class="text-sm">{{ vocal.name }}</p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

              <template>
                <div v-show="this.paginate_total > 25" class="flex items-center border-t border-gray-200 justify-between bg-white px-4 py-3 sm:px-6">
                  <div class="flex flex-1 justify-center sm:hidden">
                    <button @click="prevPage()" class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</button>
                    <button @click="nextPage()" class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Next</button>
                  </div>
                  <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                      <p class="text-sm text-gray-700">
                        Showing
                        <span class="font-medium">{{ this.paginate_from }}</span>
                        to
                        <span class="font-medium">{{ this.paginate_to }}</span>
                        of
                        <span class="font-medium">{{ this.paginate_total}}</span>
                        results
                      </p>
                    </div>
                    <div>
                      <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <button @click="changePage(1)" type="button" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                          <span class="sr-only">Previous</span>
                          <ChevronDoubleLeftIcon class="h-5 w-5" aria-hidden="true" />
                        </button>

                          <button @click="prevPage()" type="button" class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                            <span class="sr-only">Previous</span>
                            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                          </button>
                          <span v-show="this.paginate_currPage > 1" class="cursor-default	relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
                          <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" -->
                          <button type="button" aria-current="page" class="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{{ this.paginate_currPage }}</button>
                          <button v-show="this.paginate_currPage < this.paginate_lastPage - 3" @click="changePage(paginate_currPage + 1)" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">{{ this.paginate_currPage + 1 }}</button>
                          <button v-show="this.paginate_currPage < this.paginate_lastPage - 4" @click="changePage(paginate_currPage + 2)" class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">{{ this.paginate_currPage + 2 }}</button>
                          <span v-show="this.paginate_currPage <= this.paginate_lastPage - 6" class="cursor-default	relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
                          <button v-show="this.paginate_currPage < this.paginate_lastPage - 2" @click="changePage(paginate_lastPage - 2)" class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">{{ this.paginate_lastPage - 2}}</button>
                          <button v-show="this.paginate_currPage < this.paginate_lastPage - 1" @click="changePage(paginate_lastPage - 1)" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">{{ this.paginate_lastPage - 1 }}</button>
                          <button v-show="this.paginate_currPage !== this.paginate_lastPage" @click="changePage(paginate_lastPage)" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">{{ this.paginate_lastPage }}</button>
                          <button @click="nextPage()" type="button" class="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                            <span class="sr-only">Next</span>
                            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                          </button>

                        <button @click="changePage(paginate_lastPage)" type="button" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                          <span class="sr-only">Next</span>
                          <ChevronDoubleRightIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              </template>

            </div>
              <NoDataMsgWrapper v-show="no_results" class="max-w-4xl">
                        There are no artists to show with your selected filters.  Please refine your search.
              </NoDataMsgWrapper>
          </div>
        </div>
      </div>
    </div>

    <FaveManagement
            ref="faveModal"
            @forcePageUpdate="forceRerender"
            :Artist="addArtist"
            :private-invites="privateVenues"
            :private-invite-ids="privateVenueIds"
            :private-artist-venues-prop="privateArtistVenues"
            @privateVenueAdded="updatePrivateVenues($event)"
    />
  </div>
</template>

<style>


.disabled{
  @apply bg-gray-400 cursor-not-allowed;
}

/*
.black-placeholder span{
  @apply text-black;
}*/

</style>

<script>
import appConfig from '@/app.config'
import slugify from 'slugify';
import { apiComputed, apiMethods } from '@/state/helpers.js'
import formatDate from  '@/utils/format-date.js'
import InlineNotification from '../../components/notifications/inline.vue'
import Header from '../../components/listing/header.vue'
import FaveManagement from '../../components/modal/FaveManagement.vue'
import {
   TRichSelect, TInput, TSelect, TCheckbox
} from 'vue-tailwind/dist/components';

import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, EyeIcon, StarIcon } from '@vue-hero-icons/solid'

import NProgress from 'nprogress'
import client from "@/utils/client"
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';
import router from "@/router";


export default {
  components: {
    ChevronLeftIcon, ChevronRightIcon,ChevronDoubleLeftIcon,ChevronDoubleRightIcon, EyeIcon, StarIcon,
    InlineNotification,
    Header,
    TRichSelect,
    TInput,
    TSelect,
    TCheckbox,
    FaveManagement,
    NoDataMsgWrapper
},
  data() {
    return {
      appConfig,
      paginate_from: null,
      paginate_to: null,
      paginate_total: null,
      paginate_currPage: 1,
      paginate_lastPage: null,
      showFaveManagement: false,
      addArtist: {},
      privateVenueIds: [],
      styles: [],
      no_results: false,
      isLoading: false,
      results: [],
      setRegion: null,
      setArea: null,
      locations: {},
      areas: {},
      places: {},
      compFaveslists: [],
      favedArtists: [], 
      selections:{
        tier: '',
        more_infos: [],
        name: '',
        vocals: [],
        genres: [],
        covers: [],
        location: '',
        search_phrase: '',
        search_referral: '',
        has_media: true
      },
      filters: {
        show_covers: false,
        show_genres: false,
        show_vocals: false,
        show_more_infos: false,
        more_infos: [],

      },
        privateArtistVenues: [],
        privateVenues: []
    };
  },
    watch: {
      setRegion(val) {
        this.selections.location = val;
        this.setArea = null;
        this.areas = this.locations.find((f) => f.id == val).all_children;
      },
      setArea(val) {
        if (val !== null) {
          this.selections.location = val;
          this.places = this.areas.find((f) => f.id == val).all_children;
        }
      }
    },
    created() {
      this.getPrivateArtistVenues().then(response => {
          this.privateArtistVenues = response.data.data
      })
    },
    computed:{
        ...apiComputed,
        has_filters(){
          return this.selections.tier != '' || this.selections.search_phrase.length > 0|| this.selections.search_referral.length > 0;
        },
        isFavesLoaded(){
          return this.isLoading;
        },
        artistTypeSelected() {
            return this.selections.tier !== ''
        },
        user(){
          return this.$store.getters['user/userData'];  
        },
        is_advanced_search(){
          return this.user && this.user.super_user == true;
        }
    },
     mounted(){
      this.getTiers();

       client.get('locations-without-countries')
           .then(response => (this.locations = response.data))
           .finally(() => { this.isLoading = true; });

      client.get('/favourites-lists/').then((resp) =>{
        this.compFaveslists = resp.data.data;
        this.isLoading = true;
      })
          .finally(() => {
            NProgress.done();
          });


    },
    methods: {
      artistGenreFirst(val) {
        return val ? val.name : null;
      },
      navigate(url, blank = true) {
          let route = router.resolve({ path: url });
          blank ? window.open(route.href, '_blank') : window.open(route.href);
      },
      forceRerender(){
        this.getFavesLists().then((resp) =>{
          this.compFaveslists = resp;
          this.isLoading = true;
          this.loadData();
        });
      },
      changeTiers(){
        let tier = this.selections.tier;

        let selectedTier = this.tiers.find(f => f.id == tier);

        this.selections.specialities = [];
        this.filters.show_covers = Array.isArray(selectedTier.covers) && selectedTier.covers.length > 1;
        this.filters.show_genres = Array.isArray(selectedTier.genres) && selectedTier.genres.length > 1;
        this.filters.show_vocals = Array.isArray(selectedTier.vocals) && selectedTier.vocals.length > 1;
        this.filters.show_more_infos = Array.isArray(selectedTier.more_info) && selectedTier.more_info.length > 1;

        this.filters.cover_originals = selectedTier.covers;
        this.filters.more_infos = selectedTier.more_info
        this.filters.vocals = selectedTier.vocals;
        this.filters.genres = selectedTier.genres;

      },
      ...apiMethods,
      formatDate,
      slugify,
      filter(){
          this.paginate_currPage = 1;
          this.loadData();
      },
      set_disabled(value) {
        return (value == '' || value == null || value === false) ? 'hidden' : ''
      },
      prevPage(){
        if (this.paginate_currPage !== 1) {
          this.paginate_currPage = this.paginate_currPage - 1;
          this.loadData();
        }
      },
      nextPage(){
        if (this.paginate_currPage !== this.paginate_lastPage){
          this.paginate_currPage = this.paginate_currPage + 1;
          this.loadData();
        }
      },
      changePage(val){
        window.scrollTo(0,0);
        this.paginate_currPage = val;
        this.loadData();
      },
      async loadData(){

        this.no_results = false;

        if (!this.has_filters)
        {
          return;
        }

        NProgress.start(); 

        await client.get('/favourites-lists-artist-directory/').then((resp) =>{
          this.compFaveslists = resp.data.data;
        })


        this.compFaveslists.map((first) => {
          first.artists.map((result) => {
              this.favedArtists.push(result.id);
          })
        })

        let selectedTags = [];

        selectedTags.push(
            ...this.selections.covers,
            ...this.selections.genres,
            ...this.selections.more_infos,
            ...this.selections.vocals,
        );

        selectedTags = selectedTags.join(',');

        let tagFilter = "";

        tagFilter += `&filter[covers]=${this.selections.covers}`
        tagFilter += `&filter[genres]=${this.selections.genres.join(',')}`
        tagFilter += `&filter[more_infos]=${this.selections.more_infos.join(',')}`

        // if (selectedTags.length){
        //   tagFilter = '&filter[artistTag]=' + selectedTags;
        // }

        let endpoint = "/artist-directory";
        let referralFilter = "";

        if (this.is_advanced_search){
          endpoint = "/artist-directory-super";
          referralFilter = '&filter[user.referral_code]=' + this.selections.search_referral;
        }

          let hasMedia = this.selections.has_media ? '&filter[has_media]=true' : '';


        if (this.user.is_opus)
        {
          this.selections.location = 481; // middlesbrough for demo
        }

          client.get(endpoint + '?page=' + this.paginate_currPage + '&filter[name]=' + this.selections.search_phrase + '&filter[tier]=' + this.selections.tier + '&filter[location]=' + this.selections.location + hasMedia + tagFilter + referralFilter).then((resp) => {
          this.results = resp.data.data;

          this.results.map((m, index) => {
            this.results[index].genres = [];
            this.results[index].covers = [];
            this.results[index].vocals = [];
            this.results[index].more_info = [];

            m.specialities.forEach((e) => {
              this.results[index].genres = this.results[index].genres.concat(e.genres.filter(g => {
                return !this.results[index].genres.find((genre) => {
                  return genre.id === g.id
                })
              }));

              this.results[index].covers = this.results[index].covers.concat(e.covers.filter(g => {
                return !this.results[index].covers.find((cover) => {
                  return cover.id === g.id
                })
              }));

              this.results[index].vocals = this.results[index].vocals.concat(e.vocals.filter(g => {
                return !this.results[index].vocals.find((vocal) => {
                  return vocal.id === g.id
                })
              }));

              this.results[index].more_info = this.results[index].more_info.concat(e.more_info.filter(g => {
                return !this.results[index].more_info.find((more_info) => {
                  return more_info.id === g.id
                })
              }));
            })
          });

          this.paginate_to = resp.data.to;
          this.paginate_from = resp.data.from;
          this.paginate_total = resp.data.total;
          this.paginate_lastPage = resp.data.last_page;
          this.paginate_currPage = resp.data.current_page;

          if (!this.results.length){
            this.no_results = true;
          }
        }).finally(() => {

          NProgress.done();
        });

      },
      updatePrivateVenues($event) {
          this.privateVenues = $event
      },
      setPrivateArtistIds(privateArtists) {
          return privateArtists.map(artist => {
              return artist.id
          })
      }
    }
};
</script>
